import Button from '@mui/material/Button';
import * as React from 'react';

const CustomButton = (props) => {
  return (
    <>
      <Button
        className={props.className || 'grayButton'}
        variant="contained"
        onClick={props.onClick}
        disabled={props.disabled}
        size="small"
      >
        {props.title}
      </Button>
    </>
  );
};

export default CustomButton;
