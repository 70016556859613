import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  InputAdornment,
  TextField,
  Box,
  Button,
  Modal,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Draggable from 'react-draggable';

import { useCustomContext } from '../../common/Context';
import { CustomApi } from '../../common/CustomApi';

const FormModal = (props) => {
  const { showLoading, hideLoading } = useCustomContext();

  const {
    open,
    handleClose,
    setFields,
    setData,
    modalTitle,
    addData,
    editData,
    testTypeData,
    handleEmailModal,
    handleSmsModal,
  } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [editPw, setEditPw] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [imageData, setImageData] = useState(null);
  const fileInputRef = useRef(null);
  const [readOnly, setReadOnly] = useState(true);
  const [conrfirmOpen, setConfirmOpen] = useState(false);
  const { showMsg } = useCustomContext();
  const [userPhoneCheck, setUserPhoneCheck] = useState(true);
  const [userEmailCheck, setUserEmailCheck] = useState(true);
  const [userPwCheck, setUserPwCheck] = useState(true);
  const [idCheck, setIdCheck] = useState(true);

  const handleConfirmClickOpen = (e) => {
    if (editData) {
      if (e.target.id === 'userPw') {
        if (readOnly) {
          setConfirmOpen(true);
        } else {
          setConfirmOpen(false);
        }
      }
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handlefieldClick = (e) => {
    if (editData) {
      setConfirmOpen(false);
      setReadOnly(false);
      setEditPw(true);
      e.preventDefault(); // 기본 동작을 막음
      e.stopPropagation(); // 이벤트 전파 중단
    }
  };

  function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  useEffect(() => {
    if (setData) {
      setFieldValues(setData);
      setImageData(null);
      getFile(setData);
      setEditPw(false);
      setReadOnly(true);
    }
  }, [setData]);

  const togglePasswordVisibility = () => {
    if (!editPw) {
      setConfirmOpen(true);
    } else {
      setPasswordVisible(!passwordVisible);
    }
  };

  const handleInputChange = (e, fieldId) => {
    const { value } = e.target;

    setFieldValues((prev) => ({
      ...prev,
      [fieldId]: value,
    }));

    // 유효성 검사 로직 추가
    if (fieldId === 'userPhone') {
      checkPhonenumber(e);
    } else if (fieldId === 'userEmail') {
      checkEmail(e);
    } else if (fieldId === 'userPw') {
      checkPassword(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (fileInputRef.current && !imageData) {
    //   fileInputRef.current.setCustomValidity('파일을 선택해 주세요.');
    //   fileInputRef.current.reportValidity();
    // }

    if (e.currentTarget.checkValidity()) {
      const data = {
        ...fieldValues,
        editPw: editPw,
      };
      addData ? addData(data) : editData ? editData(data) : e.stopPropagation();
    } else {
      e.stopPropagation();
    }
    setUserPhoneCheck(true);
    setUserEmailCheck(true);
    setUserPwCheck(true);
    setIdCheck(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileKB = file.size / 1024;

      if (fileKB > 50) {
        showMsg('warning', '기관로고이미지는 50KB를 초과할 수 없습니다.');
      } else {
        readImage(file);

        setFieldValues((prev) => ({
          ...prev,
          instImgFile: file,
        }));
      }
    }
  };

  const readImage = (e) => {
    if (e) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(e);
    }
  };

  const getFile = async (e) => {
    if (!e.projectCd || !e.instImgFile || !setFields.find((field) => field.type === 'file')) return;

    try {
      showLoading();

      const res = await CustomApi.post(`/api/common/selectLogoImg`, e, { responseType: 'arraybuffer' });

      if (res.status == 200) {
        const mimeType = res.headers['content-type'];
        const blob = new Blob([res.data], { type: mimeType });
        blob.lastModifiedDate = new Date();
        blob.name = e.instImgName;

        readImage(blob);

        setFieldValues((prev) => ({
          ...prev,
          instImgFile: blob,
        }));
      } else {
        showMsg('error', '에러가 발생하였습니다.');
      }
    } catch (err) {
      showMsg('error', '에러가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const hadleMoldalClose = () => {
    setReadOnly(true);
    setUserPhoneCheck(true);
    setUserEmailCheck(true);
    setUserPwCheck(true);
    setIdCheck(true);
    handleClose();
  };

  // 핸드폰 번호 유효성
  const checkPhonenumber = (e) => {
    // if (e === undefined) return;
    const regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    const phoneNumber = e.target.value || e;

    if (phoneNumber.length === 11) {
      if (regExp.test(phoneNumber)) {
        setUserPhoneCheck(true);
      } else {
        showMsg('error', '유효하지 않은 핸드폰번호 형식입니다.');
        setUserPhoneCheck(false);
      }
    } else {
      showMsg('error', '입력된 핸드폰번호의 길이가 11자리가 아닙니다.');
      setUserPhoneCheck(false);
    }
  };

  // 이메일 유효성
  const checkEmail = (e) => {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const email = e.target.value || e;
    if (regExp.test(email)) {
      setUserEmailCheck(true);
    } else {
      showMsg('error', '유효하지 않은 이메일 형식입니다.');
      setUserEmailCheck(false);
    }
  };

  // 비밀번호 유효성
  const checkPassword = (e) => {
    //  8자 이상 영문, 숫자, 특수문자 조합
    var regExp = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*`])[0-9a-zA-Z!@#$%^&*`]{8,}$/;

    const password = e.target.value || e;

    if (regExp.test(password)) {
      setUserPwCheck(true);
    } else {
      showMsg('error', '비밀번호는 8자이상, 숫자, 영문자, 특수문자를 포함해야 합니다.');
      setUserPwCheck(false);
    }
  };

  const handleChange = (event) => {
    let { value } = event.target;

    // 한글을 포함하고 있다면
    if (/[\u3131-\uD79D]/.test(value)) {
      showMsg('error', '한글 입력은 불가합니다.');
      setIdCheck(false);
    } else {
      setIdCheck(true);
    }
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
    setPasswordVisible(!passwordVisible);
  };

  // 조건에 따라 높이를 설정
  let paperHeight = '700px'; // 기본 높이는 500px로 설정

  if (setFields.length > 0 && setFields[0].title === '수험번호') {
    if (setFields.length < 3) {
      paperHeight = '200px';
    } else {
      paperHeight = '550px';
    }
  } else if (setFields.length > 0 && setFields[0].title === '관리자명') {
    paperHeight = '460px';
  }

  const paperStyle = {
    overflowY: 'auto',
    height: paperHeight,
  };

  return (
    <Modal
      open={open}
      onClose={hadleMoldalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box id="modal" style={{ maxHeight: '90vh', display: 'flex' }} sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="modalTitle">{modalTitle}</div>

          <form onSubmit={handleSubmit}>
            <Paper elevation={0} style={paperStyle}>
              {setFields.map((row, idx) =>
                !editData && (row.id === 'emailSend' || row.id === 'smsSend') ? (
                  <></>
                ) : (
                  <div key={idx}>
                    <div className="modalText">
                      {row.title} {row.required && <span style={{ color: 'red' }}>*</span>}
                      {row.type === 'file' && (
                        <IconButton aria-label="delete" size="inherit" onClick={handleFileClick}>
                          <FileUploadIcon fontSize="inherit" />
                          <VisuallyHiddenInput type={row.type} onChange={handleImageChange} ref={fileInputRef} />
                        </IconButton>
                      )}
                      {row.desc && <span style={{ color: 'gray', fontSize: '12px' }}>({row.desc})</span>}
                    </div>
                    {row.type === 'select' ? (
                      <>
                        <Select
                          sx={{
                            '& .MuiSelect-select': {
                              color: 'black',
                            },
                            '& .MuiMenuItem-root': {
                              color: 'black',
                            },
                            height: '40px',
                            width: '120px',
                            margin: '0 35px 5px 50px',
                          }}
                          // className="input"
                          id={row.id}
                          value={fieldValues[row.id] || ''}
                          onChange={(e) => {
                            row.onChange(e);
                            handleInputChange(e, row.id);
                          }}
                          required={row.required}
                        >
                          {row.setItem.map((item, idx) => (
                            <MenuItem key={idx} value={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          sx={{
                            width: '80px',
                            marginRight: '35px',
                            padding: '0px',
                          }}
                          disabled={true}
                          // className="input"
                          id={row.id + 'text'}
                          size="small"
                          type={'text'}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={testTypeData?.testKindNm || ''}
                        />
                        <TextField
                          sx={{
                            width: '80px',
                            margin: '0px',
                            padding: '0px',
                          }}
                          disabled={true}
                          // className="input"
                          id={row.id + 'text'}
                          size="small"
                          type={'text'}
                          InputProps={{
                            readOnly: true,
                          }}
                          value={testTypeData?.testTm ? testTypeData.testTm + '분' : ''}
                        />
                      </>
                    ) : row.type === 'file' ? (
                      <img className="input" src={imageData} style={{ width: '350px', height: '80px' }} />
                    ) : (
                      <div onClick={handleConfirmClickOpen} style={{ display: 'inline-block' }}>
                        {row.id !== 'emailSend' && row.id !== 'smsSend' ? (
                          <TextField
                            disabled={editData && row.disabled ? true : false}
                            className="input"
                            id={row.id}
                            size="small"
                            type={row.type === 'password' ? (passwordVisible ? 'text' : 'password') : row.type}
                            InputProps={{
                              readOnly: readOnly === false ? false : editData && row.readOnly,
                              endAdornment: (
                                <InputAdornment position="end" onClick={togglePasswordVisibility}>
                                  {row.type === 'password' && (
                                    <span onClick={handleIconClick}>
                                      {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </span>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            value={fieldValues[row.id] || ''}
                            onChange={(e) => handleInputChange(e, row.id)}
                            required={row.required}
                            error={
                              (row.id === 'userPhone' && !userPhoneCheck) ||
                              (row.id === 'userEmail' && !userEmailCheck) ||
                              (row.id === 'userPw' && !userPwCheck) ||
                              (row.id === 'userId' && !idCheck)
                            }
                            inputProps={
                              (row.id === 'userNm' && { maxLength: 10 }) ||
                              (row.id === 'userNum' && { maxLength: 20 }) ||
                              (row.id === 'userPhone' && { maxLength: 11 }) ||
                              (row.id === 'userId' && {
                                maxLength: 20,
                                onChange: handleChange,
                              }) ||
                              (row.id === 'userDept' && { maxLength: 25 }) ||
                              (row.id === 'userPosition' && { maxLength: 8 }) ||
                              (row.id === 'instNm' && { maxLength: 128 }) ||
                              (row.id === 'instDomain' && { maxLength: 20 }) ||
                              (row.id === 'jobTitle' && { maxLength: 100 }) ||
                              (row.id === 'jobField' && { maxLength: 100 })
                            }
                          />
                        ) : (
                          <div style={{ marginLeft: '190px', paddingBottom: '20px', fontSize: '18px' }}>
                            {row.id === 'emailSend' && (
                              <Button id="emailPreview" onClick={handleEmailModal}>
                                email 미리보기
                              </Button>
                            )}

                            {row.id === 'smsSend' && (
                              <Button id="smsPreview" onClick={handleSmsModal}>
                                sms 미리보기
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              )}

              <Dialog
                open={conrfirmOpen}
                onClose={handleConfirmClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  확인
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>비밀번호를 변경하시겠습니까?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handlefieldClick}>확인</Button>
                  <Button onClick={handleConfirmClose} color="error">
                    취소
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
            <div className="modalButton">
              {userPwCheck && idCheck && userEmailCheck && userPhoneCheck ? (
                <Button type="submit" className="submitButton" variant="contained">
                  {addData ? '등록' : editData ? '수정' : ''}
                </Button>
              ) : (
                <Button className="disabledButton" variant="contained">
                  {addData ? '등록' : editData ? '수정' : ''}
                </Button>
              )}
              <Button
                className="cancleButton"
                onClick={() => {
                  hadleMoldalClose();
                  setReadOnly(true);
                }}
                color="error"
                variant="contained"
              >
                취소
              </Button>
            </div>
          </form>
        </Typography>
      </Box>
    </Modal>
  );
};

export default FormModal;
