import PropTypes from 'prop-types';
import React from 'react';
import Particle from '../particles/Particle';

const HeroSliderOneSingle = ({ title, data }) => {
  return (
    <div
      className="slide d-flex bg-image--1 align__center poss--relative ht__bradcaump__area"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + data.backgroundImage})`,
        height: '500px',
      }}
    >
      <div className="ht__bradcaump__container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bradcaump__inner text-center">
                <h2 className="bradcaump-title">{title}</h2>
                <nav className="bradcaump-inner">
                  <span className="breadcrumb-item active">Home</span>
                  <span className="brd-separetor"> / </span>
                  <span className="breadcrumb-item active">{title}</span>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* particle */}
      <Particle />
    </div>
  );
};

HeroSliderOneSingle.propTypes = {
  data: PropTypes.object,
};

export default HeroSliderOneSingle;
