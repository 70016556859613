import React, { Fragment, useState, useRef, useEffect } from 'react';
import LayoutAdmin from '../layouts/LayoutAdmin';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';
import { useCustomContext } from '../common/Context';
import { CustomApi } from '../common/CustomApi';

const LoginRegister = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useCustomContext();
  const { showMsg } = useCustomContext();
  const inputId = useRef(null);
  const inputPw = useRef(null);
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  useEffect(() => {
    localStorage.clear();
    inputId.current.focus();
  }, []);

  const handleLogin = async () => {
    try {
      showLoading();
      const res = await CustomApi.post(`/admin/login`, { username: id, password: pw });
      if (res.status == 200) {
        if (res.data.resultCode === 0) {
          localStorage.clear();
          localStorage.setItem('tokenType', res.data.resultData.grantType);
          localStorage.setItem('admin-accessToken', res.data.resultData.accessToken);
          localStorage.setItem('routePath', res.data.resultData.routePath);
          localStorage.setItem('loginId', res.data.resultData.userId);
          localStorage.setItem('userNm', res.data.resultData.userNm);
          navigate(`${process.env.PUBLIC_URL + res.data.resultData.routePath}`);
        } else {
          showMsg('warning', res.data.resultMsg);
        }
      } else {
        showMsg('error', res.data.resultMsg);
      }
    } catch (err) {
      showMsg('error', '오류가 발생하였습니다.');
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Fragment>
      <LayoutAdmin title="LOGIN">
        {/* login register content */}
        <div className="dg__account section-padding--xl">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tab.Container defaultActiveKey="login">
                  <Nav variant="pills" className="acount__nav justify-content-center">
                    <Nav.Item>
                      <Nav.Link eventKey="login">Login</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="login">
                      <div className="single__account">
                        <div className="input__box">
                          <span>ID</span>
                          <input type="text" onChange={(e) => setId(e.target.value)} ref={inputId} />
                        </div>
                        <div className="input__box">
                          <span>Password</span>
                          <input
                            type="password"
                            onChange={(e) => setPw(e.target.value)}
                            onKeyDown={(e) => onKeyDown(e)}
                            ref={inputPw}
                          />
                        </div>
                        <button className="account__btn" onClick={handleLogin}>
                          Login
                        </button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </LayoutAdmin>
    </Fragment>
  );
};

export default LoginRegister;
