import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ image }) => {
  return (
    <div className="logo">
      {/* 280*60 */}
      <img src={image} alt="logo images" width={'280px'} height={'40px'} />
    </div>
  );
};

Logo.propTypes = {
  image: PropTypes.string,
};

export default Logo;
