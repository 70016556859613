import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/react-editor';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useCustomContext } from '../../../common/Context';
import { CustomApi } from '../../../common/CustomApi';
import CustomButton from '../../../components/Button/CustomButton';
import FormModal from '../../../components/modal/FormModal';
import SearchCondition from '../../../containers/Search/SearchCondition';
import CustomTable from '../../../containers/Table/CustomTable';
import LayoutAdmin from '../../../layouts/LayoutAdmin';

/**
 * [ 프로젝트 > 프로젝트 관리 ]
 *
 * @returns
 */
const ProjectMng = () => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const paperHeaders = [
    { title: 'No', id: 'no', type: 'text' },
    { title: '수험번호', id: 'userNum', type: 'text' },
    { title: '이름', id: 'userNm', type: 'text' },
    { title: '응답문항수', id: 'questionRsCnt', type: 'text' },
    {
      title: '검사상태',
      id: 'testState',
      type: 'imageLabel',
      imageLabelClassName: (value) => {
        switch (value) {
          case '검사전':
            return 'stateGray';
          case '검사중':
            return 'stateGreen';
          case '검사완료':
            return 'stateRed';
        }
      },
    },
  ];

  const onlineHeaders = [
    { title: 'no', id: 'no', type: 'text' },
    { title: '수험번호', id: 'userNum', type: 'text' },
    { title: '이름', id: 'userNm', type: 'text' },
    { title: '이메일', id: 'userEmail', type: 'text' },
    { title: '핸드폰번호', id: 'userPhone', type: 'text' },
    { title: 'ID', id: 'userId', type: 'text' },
    { title: '전송상태(SMS)', id: 'smsSendDt', type: 'text' },
    { title: '전송상태(E-Mail)', id: 'emailSendDt', type: 'text' },
    { title: '접속시간(부터)', id: 'testStDt', type: 'text' },
    { title: '종료시간(까지)', id: 'testEnDt', type: 'text' },
    { title: '검사진행시간', id: 'testTm', type: 'text' },
    { title: '응답문항수', id: 'questionRsCnt', type: 'text' },
    {
      title: '검사상태',
      id: 'testState',
      type: 'imageLabel',
      imageLabelClassName: (value) => {
        switch (value) {
          case '검사전':
            return 'stateGray';
          case '검사중':
            return 'stateGreen';
          case '검사완료':
            return 'stateRed';
        }
      },
    },
  ];

  const defaultField = [
    { title: '기관명', id: 'instNm', type: 'text', required: true },
    { title: '기관로고이미지', id: 'instImgFile', type: 'file', desc: '280*60pix, 50KB' },
    { title: '기관도메인명', id: 'instDomain', type: 'text', required: true },
    { title: '채용공고명', id: 'jobTitle', type: 'text', required: true },
    { title: '채용분야', id: 'jobField', type: 'text', required: true },
    { title: '검사인원수', id: 'testCnt', type: 'number', required: true },
    {
      title: '검사명',
      id: 'testTypeCd',
      type: 'select',
      required: true,
      setItem: [
        { value: 'A', text: 'PAPI-A형', kind: 'W' },
        { value: 'B', text: 'PAPI-B형', kind: 'O' },
        { value: 'C', text: 'PAPI-C형', kind: 'W' },
        { value: 'D', text: 'PAPI-D형', kind: 'O' },
      ],
      onChange: (e) => {
        testTypeChange(e);
      },
    },
  ];

  const paperField = [
    { title: '기관명', id: 'instNm', type: 'text', required: true },
    { title: '기관로고이미지', id: 'instImgFile', type: 'file', desc: '280*60pix, 50KB' },
    { title: '기관도메인명', id: 'instDomain', type: 'text', required: true },
    { title: '채용공고명', id: 'jobTitle', type: 'text', required: true },
    { title: '채용분야', id: 'jobField', type: 'text', required: true },
    { title: '검사인원수', id: 'testCnt', type: 'number', required: true },
    {
      title: '검사명',
      id: 'testTypeCd',
      type: 'select',
      required: true,
      setItem: [
        { value: 'A', text: 'PAPI-A형', kind: 'W' },
        { value: 'B', text: 'PAPI-B형', kind: 'O' },
        { value: 'C', text: 'PAPI-C형', kind: 'W' },
        { value: 'D', text: 'PAPI-D형', kind: 'O' },
      ],
      onChange: (e) => {
        testTypeChange(e);
      },
    },
    { title: '검사일', id: 'testDt', type: 'date', required: true },
  ];

  const onlineField = [
    { title: '기관명', id: 'instNm', type: 'text', required: true },
    { title: '기관로고이미지', id: 'instImgFile', type: 'file', desc: '280*60pix, 50KB' },
    { title: '기관도메인명', id: 'instDomain', type: 'text', required: true },
    { title: '채용공고명', id: 'jobTitle', type: 'text', required: true },
    { title: '채용분야', id: 'jobField', type: 'text', required: true },
    { title: '검사인원수', id: 'testCnt', type: 'number', required: true },
    {
      title: '검사명',
      id: 'testTypeCd',
      type: 'select',
      required: true,
      setItem: [
        { value: 'A', text: 'PAPI-A형', kind: 'W' },
        { value: 'B', text: 'PAPI-B형', kind: 'O' },
        { value: 'C', text: 'PAPI-C형', kind: 'W' },
        { value: 'D', text: 'PAPI-D형', kind: 'O' },
      ],
      onChange: (e) => {
        testTypeChange(e);
      },
    },
    { title: '검사시작일시', id: 'testFrDt', type: 'datetime-local', required: true },
    { title: '검사종료일시', id: 'testToDt', type: 'datetime-local', required: true },
  ];

  const { showLoading, hideLoading } = useCustomContext();
  const uploadUserRef = useRef(null);
  const omrReadRef = useRef(null);
  const [listView, setListView] = useState(true);
  const [selectedData, setSelectedData] = useState(null);
  const [checkedData, setCheckedData] = useState([]);
  const [searchInstList, setSearchInstList] = useState([]);
  const [searchTestTypeList, setSearchTypeList] = useState([]);
  const [searchUserNum, setSearchUserNum] = useState([]);
  const [searchUserNm, setSearchUserNm] = useState([]);
  const { showMsg } = useCustomContext();
  const [emailSendOpen, setEmailSendOpen] = useState(false);
  const [smsSendOpen, setSmsSendOpen] = useState(false);
  const [emailPreviewOpen, setEmailPreviewOpen] = useState(false);
  const [smsPreviewOpen, setSmsPreviewOpen] = useState(false);
  const [previewContents, setPreviewContents] = useState(null);
  const [testTypeList, setTestTypeList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [btnSt, setBtnSt] = useState(null);
  const [notSendCnt, setNotSendCnt] = useState(null);
  const testKindNm = checkedData.find((data) => data.testKindNm === '지면');

  const ws = useRef(null);

  useEffect(() => {
    const connectWebSocket = () => {
      //ws.current = new WebSocket('ws://' + window.location.hostname + ':81/ws');
      if(window.location.protocol === 'https:') {
        ws.current = new WebSocket('wss://' + window.location.hostname + '/ws');
      } else {
        ws.current = new WebSocket('ws://' + window.location.hostname + '/ws');
      }
      // ws.current = new WebSocket('ws://' + window.location.hostname + ':8080/ws');

      ws.current.onopen = () => {
        console.log('WebSocket connection established');
      };

      ws.current.onclose = () => {
        console.log('WebSocket connection closed');
        setTimeout(() => {
          connectWebSocket();
        }, 200);
      };
    };

    const keepAlive = () => {
      if (ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ type: 'ping' }));
      }
      setTimeout(keepAlive, 30000); // 30초마다 ping 전송
    };

    connectWebSocket();
    keepAlive();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (btnSt) {
      setConfirmOpen(true);
    }
  }, [btnSt]);

  const [search, setSearch] = useState({
    instNm: '',
    testTypeNm: '',
    testKindNm: '',
    projectSt: '',
  });

  const [searchUser, setSearchUser] = useState({
    userNum: '',
    userNm: '',
    smsSendDt: '',
    emailSendDt: '',
    testState: '',
  });

  const handleSendClose = () => {
    setSmsSendOpen(false);
    setEmailSendOpen(false);
    setSmsPreviewOpen(false);
    setEmailPreviewOpen(false);
  };
  /**
   * 프로젝트 관리 테이블
   */
  const [projectTable, setProjectTable] = useState({
    headers: [
      { title: 'No', id: 'no', type: 'text' },
      { title: '기관명', id: 'instNm', type: 'text' },
      { title: '기관도메인명', id: 'instDomain', type: 'text' },
      { title: '채용공고명', id: 'jobTitle', type: 'text' },
      { title: '채용분야', id: 'jobField', type: 'text' },
      { title: '검사명', id: 'testTypeNm', type: 'text' },
      { title: '진행방식', id: 'testKindNm', type: 'text' },
      { title: '검사일', id: 'testDt', type: 'text' },
      { title: '검사시작일시', id: 'testFrDt', type: 'text' },
      { title: '검사종료일시', id: 'testToDt', type: 'text' },
      { title: '대상자 인원수', id: 'userCnt', type: 'text' },
      { title: '검사완료 대상자수', id: 'endUserCnt', type: 'text' },
      {
        title: '프로젝트 상태',
        id: 'projectSt',
        type: 'imageLabel',
        imageLabelClassName: (value) => {
          switch (value) {
            case '개시전':
              return 'stateGray';
            case '진행중':
              return 'stateGreen';
            case '검사종료':
              return 'stateRed';
          }
        },
      },
    ],
    datas: [],
  });

  /**
   * 프로젝트 대상자 테이블
   */
  const [userTable, setUserTable] = useState({
    headers: [],
    datas: [],
  });

  /**
   * 프로젝트 등록/수정 모달
   */
  const [projectModal, setProjectModal] = useState({
    setFields: defaultField,
    title: '',
    open: false,
    data: {},
    addData: '',
    editData: '',
  });

  const testTypeChange = (e) => {
    setProjectModal((prev) => ({
      ...prev,
      setFields: e.target.value === 'A' || e.target.value === 'C' ? paperField : onlineField,
      testTypeData: testTypeList.find((data) => data.value === e.target.value),
    }));
  };

  /**
   * 대상자 등록/수정 모달
   */
  const [modal, setModal] = useState({
    setFields: [
      { title: '수험번호', id: 'userNum', type: 'text', required: true, disabled: true },
      { title: '이름', id: 'userNm', type: 'text', required: true },
      { title: '이메일', id: 'userEmail', type: 'text', required: true },
      { title: '핸드폰번호', id: 'userPhone', type: 'text', required: true },
      { title: 'ID', id: 'userId', type: 'text', required: true, disabled: true },
      { title: 'PW', id: 'userPw', type: 'password', required: true, readOnly: true },
      { title: '이메일 전송', id: 'emailSend' },
      { title: 'SMS 전송', id: 'smsSend' },
    ],
    title: '',
    open: false,
    data: {},
    addData: '',
    editData: '',
    projectCd: '',
  });

  /**
   * 프로젝트 목록 행 클릭 시
   * 대상자 목록 조회 함수 호출
   */
  useEffect(() => {
    selectedData && user.fetchDatas();
  }, [selectedData]);

  /**
   * 대상자 화면에서 프로젝트 목록으로 이동 시
   * 선택 데이터 초기화
   */
  useEffect(() => {
    if (listView) {
      setSelectedData(null);
      project.searchInstList();
      project.searchTestTypeList();
      setSearchUser({});
      project.fetchDatas();
    } else {
      // 대상자 조회
      user.searchUserNum();
      user.searchUserNm();
    }
  }, [listView]);

  /**
   * 진행방식 검색 목록
   */
  const searchTestKindList = (e) => {
    return [{ key: '지면' }, { key: '온라인' }];
  };
  /**
   * 프로젝트 상태 검색 목록
   */
  const searchSend = (e) => {
    return [{ key: '전송' }, { key: '미전송' }];
  };

  const searchProjectStList = (e) => {
    return [{ key: '개시전' }, { key: '진행중' }, { key: '검사종료' }];
  };

  const searchUserStList = (e) => {
    return [{ key: '검사전' }, { key: '검사중' }, { key: '검사완료' }];
  };

  const handleChange = (event, newValue) => {
    if (event.target.id) {
      setSearch((prev) => ({
        ...prev,
        [event.target.id.split('-')[0]]: newValue.key,
      }));
    } else {
      setSearch((prev) => ({
        ...prev,
        [event.currentTarget.parentElement.parentElement.firstChild.id]: '',
      }));
    }
  };

  const handleUserChange = (event, newValue) => {
    if (event.target.id) {
      setSearchUser((prev) => ({
        ...prev,
        [event.target.id.split('-')[0]]: newValue.key,
      }));
    } else {
      setSearchUser((prev) => ({
        ...prev,
        [event.currentTarget.parentElement.parentElement.firstChild.id]: '',
      }));
    }
  };

  useEffect(() => {
    project.getTestType();
  }, []);

  useEffect(() => {
    project.fetchDatas();
  }, [search]);

  useEffect(() => {
    if (selectedData && selectedData.testKindNm) {
      user.fetchDatas();
    }
  }, [searchUser]);

  /**
   * 프로젝트 함수
   */
  const project = {
    getTestType: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectTestType`);
        if (res.status == 200) {
          setTestTypeList(res.data);
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },
    /** 기관명 검색 */
    searchInstList: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/searchList`, { type: 'instNm' });
        if (res.status == 200) {
          setSearchInstList(res.data);
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /** 검사유형 검색 목록  */
    searchTestTypeList: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/searchList`, { type: 'testTypeNm' });
        if (res.status == 200) {
          setSearchTypeList(res.data);
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /**
     * 프로젝트 목록 조회 API
     */

    fetchDatas: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectProjectMng`, search);
        if (res.status == 200) {
          setProjectTable((prev) => ({
            ...prev,
            datas: res.data,
          }));
          if (res.data.length > 0) {
            showMsg('info', '리스트가 정상적으로 조회가 되었습니다.');
          } else {
            showMsg('info', '등록된 프로젝트가 없습니다.');
          }
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 프로젝트 등록 API
     */
    createData: async (e) => {
      try {
        showLoading();
        let res;

        if (e.instImgFile) {
          const formData = new FormData();
          formData.append('instImgFile', e.instImgFile);
          formData.append('param', new Blob([JSON.stringify(e)], { type: 'application/json' }));

          res = await CustomApi.post(`/api/projectadmin/insertProjectMngWithFile`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } else {
          res = await CustomApi.post(`/api/projectadmin/insertProjectMngWithoutFile`, e);
        }

        if (res.status == 200) {
          project.fetchDatas();
          project.handleClose();
          showMsg('success', '입력된 정보가 등록 되었습니다.');
        } else {
          showMsg('error', '에러가 발생하였습니다.');
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 프로젝트 수정 API
     */
    updateData: async (e) => {
      try {
        showLoading();
        let res;

        if (e.instImgFile) {
          const formData = new FormData();
          formData.append('instImgFile', e.instImgFile);
          formData.append('param', new Blob([JSON.stringify(e)], { type: 'application/json' }));

          res = await CustomApi.post(`/api/projectadmin/updateProjectMngWithFile`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } else {
          res = await CustomApi.post(`/api/projectadmin/updateProjectMngWithoutFile`, e);
        }

        if (res.status == 200) {
          project.fetchDatas();
          project.handleClose();
          showMsg('success', '입력된 정보가 수정 되었습니다.');
        } else {
          showMsg('error', '입력된 정보가 수정되지 않았습니다. 필수 입력 항목을 확인해 주세요.');
        }
      } catch (err) {
        showMsg('error', '입력된 정보가 수정되지 않았습니다. 필수 입력 항목을 확인해 주세요.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 프로젝트 삭제 API
     */
    deleteData: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/deleteProjectMng`, e[0]);
        if (res.status == 200) {
          if (typeof res.data === 'number') {
            showMsg('success', '선택한 프로젝트가 삭제되었습니다.');
            project.fetchDatas();
          } else {
            showMsg('error', res.data);
          }
        } else {
          showMsg('open', 'error', '반영된 데이터가 없습니다.');
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 프로젝트 상태변경 API
     */
    updateStatus: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/updateProjectStatus`, { projectSt: e, list: checkedData });
        if (res.status == 200) {
          handleClose();
          project.fetchDatas();
          if (e === '1') {
            showMsg('success', '선택한 프로젝트의 상태가 검사중으로 변경 되었습니다.');
          } else {
            // 대상자 로그아웃
            if (ws.current) {
              ws.current.send(checkedData[0].projectCd);
            }
            showMsg('success', '선택한 프로젝트의 상태가 검사종료로 변경 되었습니다.');
          }
        } else {
          showMsg('error', '에러가 발생하였습니다.');
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 프로젝트 테이블 클릭 핸들러
     */
    handleClick: (row) => {
      setSelectedData(row);
    },
    /**
     * 프로젝트 테이블 체크 핸들러
     */
    handleCheck: (e) => {
      setCheckedData(
        projectTable.datas.filter((d) => {
          return e.some((no) => no === d.no);
        })
      );
    },
    /**
     * 프로젝트 등록 핸들러
     */
    handleAdd: () => {
      setProjectModal((prev) => ({
        ...prev,
        title: '프로젝트 등록',
        open: true,
        setFields: defaultField,
        addData: project.createData,
      }));
    },

    /**
     * 프로젝트 수정 핸들러
     */
    handleEdit: (e) => {
      const data = e[0];
      if (data.projectSt === '검사종료') {
        showMsg('error', '대상자가 등록되거나 검사중 or 검사종료된 프로젝트는 수정 불가합니다.');
      } else if (data.projectSt === '진행중') {
        showMsg('error', '대상자가 등록되거나 검사중 or 검사종료된 프로젝트는 수정 불가합니다.');
      } else if (data.projectSt === '개시전') {
        setProjectModal((prev) => ({
          ...prev,
          title: '프로젝트 수정',
          open: true,
          setFields: data.testTypeCd == 'A' || data.testTypeCd == 'C' ? paperField : onlineField,
          data: data,
          testTypeData: testTypeList.find((d) => d.value === data.testTypeCd),
          editData: project.updateData,
        }));
      }
    },
    /**
     * 프로젝트 삭제 핸들러
     */
    handleDelete: (e) => {
      const data = e[0];
      if (data.projectSt === '진행중') {
        showMsg('error', '대상자가 등록되거나 검사중 or 검사종료된 프로젝트는 삭제 불가합니다.');
      } else if (data.projectSt === '검사종료') {
        showMsg('error', '대상자가 등록되거나 검사중 or 검사종료된 프로젝트는 삭제 불가합니다.');
      } else {
        project.deleteData(Array.isArray(e) ? e : [e]);
      }
    },
    /**
     * 프로젝트 상태 변경 핸들러
     */

    handleStatus: (e) => {
      if (checkedData.length === 0) {
        showMsg('error', '선택된 데이터가 없습니다.');
        return;
      }

      if (checkedData.find((data) => data.testKindNm === '온라인')) {
        if (e === '1' && checkedData.find((data) => data.projectSt === '진행중' || data.projectSt === '검사종료')) {
          showMsg('error', '검사중 or 검사종료 상태에서는 검사개시가 불가합니다.');
          return;
        }

        if (e === '2' && checkedData.find((data) => data.projectSt === '개시전' || data.projectSt === '검사종료')) {
          showMsg('error', '개시전 or 검사종료 상태에서는 검사종료로 변경이 불가합니다. 검사개시후 변경 가능합니다.');
          return;
        }

        if (e === '1') {
          setBtnSt('검사중');
        } else {
          setBtnSt('검사종료');
        }
      } else if (checkedData.find((data) => data.testKindNm === '지면')) {
        if (checkedData.find((data) => data.projectSt === '검사종료')) {
          showMsg('error', '검사종료 상태에서는 검사종료가 불가합니다.');
          return;
        }
        setBtnSt('검사종료');
      }
    },
    /**
     * 프로젝트 모달 닫기
     */
    handleClose: () => {
      setProjectModal((prev) => ({
        ...prev,
        open: false,
        data: {},
        addData: '',
        editData: '',
        testTypeData: {},
      }));
    },
  };

  /**
   * 대상자 함수
   */
  const user = {
    /** 수험번호 목록 검색 */
    searchUserNum: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/searchList`, {
          type: 'userNum',
          projectCd: selectedData.projectCd,
        });
        if (res.status == 200) {
          setSearchUserNum(res.data);
        } else {
          showMsg('error', '대상자 리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '대상자 리스트가 정상적으로 조회되지 않았습니다. ');
      } finally {
        hideLoading();
      }
    },

    /** 이름 목록 검색 */
    searchUserNm: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/searchList`, {
          type: 'userNm',
          projectCd: selectedData.projectCd,
        });
        if (res.status == 200) {
          setSearchUserNm(res.data);
        } else {
          showMsg('error', '대상자 리스트가 정상적으로 조회되지 않았습니다. ');
        }
      } catch (err) {
        showMsg('error', '대상자 리스트가 정상적으로 조회되지 않았습니다. ');
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자 목록 조회 API
     */
    fetchDatas: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectProjectUserMng`, {
          ...selectedData,
          ...searchUser,
        });
        if (res.status === 200) {
          setUserTable((prev) => ({
            ...prev,
            headers: selectedData.testKindNm === '온라인' ? onlineHeaders : paperHeaders,
            datas: res.data,
          }));
          setListView(false);
          if (res.data.length > 0) {
            showMsg('info', '검사 대상자 리스트가 정상적으로 조회가 되었습니다.');
          } else {
            showMsg('info', '프로젝트에 등록된 대상자가 없습니다.');
          }
        } else {
          showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자 등록 API
     */
    createData: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/insertProjectUserMng`, e);
        if (res.status == 200) {
          if (typeof res.data === 'number') {
            if (res.data > 0) {
              user.fetchDatas();
              user.handleClose();
              showMsg('success', '입력된 대상자가 등록 되었습니다.');
            }
          } else {
            showMsg('error', res.data);
          }
        } else {
          showMsg('error', '입력된 대상자가 등록되지 않았습니다. ');
        }
      } catch (err) {
        showMsg('error', '입력된 대상자가 등록되지 않았습니다. ');
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자 수정 API
     */
    updateData: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/updateProjectUserMng`, {
          ...e,
          projectCd: selectedData.projectCd,
        });
        if (res.status == 200) {
          user.fetchDatas();
          user.handleClose();
          showMsg('success', '입력된 대상자가 수정 되었습니다.');
        } else {
          showMsg('error', '입력된 대상자가 수정되지 않았습니다. ');
        }
      } catch (err) {
        showMsg('error', '입력된 대상자가 수정되지 않았습니다. ');
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자 삭제 API
     */
    deleteData: async (e) => {
      try {
        console.log(e);
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/deleteProjectUserMng`, {
          projectCd: selectedData.projectCd,
          list: e,
        });
        if (res.status == 200) {
          user.fetchDatas();
          showMsg('success', '선택된 대상자가 삭제 되었습니다.');
        } else {
          showMsg('error', '에러가 발생하였습니다.');
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자엑셀업로드 API
     */
    upload: async (e) => {
      showLoading();
      const formData = new FormData();

      formData.append('file', e.target.files[0]);
      formData.append('param', new Blob([JSON.stringify(selectedData)], { type: 'application/json' }));

      try {
        const res = await CustomApi.post(`/api/projectadmin/uploadProjectUserMng`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (res.status == 200) {
          if (res.data > 0) {
            user.fetchDatas();
            showMsg('success', '대상자 엑셀 업로드가 성공하였습니다.');
          } else {
            showMsg('error', '대상자 엑셀 업로드가 실패하였습니다. 대상파일을 확인 해 주세요.');
          }
        } else {
          showMsg('error', '대상자 엑셀 업로드가 실패하였습니다. 대상파일을 확인 해 주세요.');
        }
      } catch (err) {
        showMsg('error', '대상자 엑셀 업로드가 실패하였습니다. 대상파일을 확인 해 주세요.');
      } finally {
        hideLoading();
      }
    },
    /**
     * E-Mail / SMS 전송 API
     */
    send: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/sendMessage`, {
          msgCd: e,
          projectCd: selectedData.projectCd,
        });
        if (res.status == 200) {
          if (res.data > 0) {
            user.fetchDatas();
            showMsg('success', 'e-mail(SMS) 안내문구가 검사대상자에게 전송 되었습니다.');
            handleSendClose();
          } else {
            showMsg('error', '전송된 데이터가 없습니다.');
          }
        } else {
          showMsg(
            'error',
            'e-mail(SMS) 안내문구 전송이 실패하였습니다. 대상자 등록여부, 안내문구 등록여부, 검사상태를 확인 해 주세요.'
          );
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * OMR 리딩결과 등록 API
     */
    omrRead: async (e) => {
      const formData = new FormData();

      formData.append('file', e.target.files[0]);
      formData.append('param', new Blob([JSON.stringify(selectedData)], { type: 'application/json' }));

      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/uploadOmrRead`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (res.status == 200) {
          if (typeof res.data === 'number') {
            if (res.data > 0) {
              await user.fetchDatas();
              showMsg('success', 'OMR 리딩결과 등록이 성공하였습니다.');
            } else {
              showMsg(
                'error',
                'OMR 리딩결과등록이 실패하였습니다. 프로젝트(개시전) 상태와 대상자 목록이 일치하는지 확인 해 주세요.'
              );
            }
          } else {
            showMsg('error', res.data);
          }
        } else {
          showMsg(
            'error',
            'OMR 리딩결과등록이 실패하였습니다. 프로젝트(개시전) 상태와 대상자 목록이 일치하는지 확인 해 주세요.'
          );
        }
      } catch (err) {
        showMsg(
          'error',
          'OMR 리딩결과등록이 실패하였습니다. 프로젝트(개시전) 상태와 대상자 목록이 일치하는지 확인 해 주세요.'
        );
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자 테이블 클릭 핸들러
     */
    handleClick: (row) => {
      // setSelectedData(row);
    },
    /**
     * 대상자 테이블 체크 핸들러
     */
    handleCheck: (e) => {
      setCheckedData(
        projectTable.datas.filter((d) => {
          return e.some((no) => no === d.no);
        })
      );
    },
    /**
     * 대상자 등록 핸들러
     */
    handleAdd: () => {
      const paperUser = selectedData.testKindNm === '지면';
      const setFields = paperUser
        ? [
            { title: '수험번호', id: 'userNum', type: 'text', required: true, disabled: true },
            { title: '이름', id: 'userNm', type: 'text', required: true },
          ]
        : [
            { title: '수험번호', id: 'userNum', type: 'text', required: true, disabled: true },
            { title: '이름', id: 'userNm', type: 'text', required: true },
            { title: '이메일', id: 'userEmail', type: 'text', required: true },
            { title: '핸드폰번호', id: 'userPhone', type: 'text', required: true },
            { title: 'ID', id: 'userId', type: 'text', required: true, disabled: true },
            { title: 'PW', id: 'userPw', type: 'password', required: true, readOnly: true },
            { title: '이메일 전송', id: 'emailSend' },
            { title: 'SMS 전송', id: 'smsSend' },
          ];
      setModal((prev) => ({
        ...prev,
        title: '대상자 등록',
        open: true,
        setFields: setFields,
        data: {
          projectCd: selectedData.projectCd,
          testKindNm: selectedData.testKindNm,
        },
        addData: user.createData,
      }));
    },
    /**
     * 대상자 수정 핸들러
     */
    handleEdit: (e) => {
      setModal((prev) => ({
        ...prev,
        title: '대상자 수정',
        open: true,
        setFields:
          selectedData.testKindNm === '지면'
            ? [
                { title: '수험번호', id: 'userNum', type: 'text', required: true, disabled: true },
                { title: '이름', id: 'userNm', type: 'text', required: true },
              ]
            : [
                { title: '수험번호', id: 'userNum', type: 'text', required: true, disabled: true },
                { title: '이름', id: 'userNm', type: 'text', required: true },
                { title: '이메일', id: 'userEmail', type: 'text', required: true },
                { title: '핸드폰번호', id: 'userPhone', type: 'text', required: true },
                { title: 'ID', id: 'userId', type: 'text', required: true, disabled: true },
                { title: 'PW', id: 'userPw', type: 'password', required: true, readOnly: true },
                { title: '이메일 전송', id: 'emailSend' },
                { title: 'SMS 전송', id: 'smsSend' },
              ],
        data: Array.isArray(e) ? e[0] : e,
        editData: user.updateData,
      }));
    },
    /**
     * 대상자 삭제 핸들러
     */
    handleDelete: (e) => {
      console.log('handleDelete ::: ', e);
      user.deleteData(Array.isArray(e) ? e : [e]);
    },
    /**
     * 대상자 모달 닫기
     */
    handleClose: () => {
      setModal((prev) => ({
        ...prev,
        open: false,
        data: {},
        addData: '',
        editData: '',
      }));
    },
    /**
     * 대상자엑셀업로드 핸들러
     */
    handleUpload: () => {
      if (uploadUserRef.current) {
        uploadUserRef.current.click();
      }
    },
    /**
     * E-MAIL 전송 핸들러
     */
    handleEmail: async () => {
      user.send('E');
    },
    /**
     * E-MAIL 전송 모달 열기
     */
    handleEmailModal: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectNotSendCnt`, {
          projectCd: selectedData.projectCd,
          msgCd: 'E',
        });
        if (res.status == 200) {
          setNotSendCnt(res.data);
          setEmailSendOpen(true);
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * E-MAIL 미리보기 모달 열기
     */
    handleEmailPreviewModal: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectMsgContents`, {
          projectCd: selectedData.projectCd,
          msgCd: 'E',
        });
        if (res.status == 200) {
          setPreviewContents(res.data);
          setEmailPreviewOpen(true);
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * SMS 전송 핸들러
     */
    handleSMS: async () => {
      user.send('S');
    },
    /**
     * SMS 전송 모달 열기
     */
    handleSmsModal: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectNotSendCnt`, {
          projectCd: selectedData.projectCd,
          msgCd: 'S',
        });
        if (res.status == 200) {
          setNotSendCnt(res.data);
          setSmsSendOpen(true);
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * SMS 미리보기 모달 열기
     */
    handleSmsPreviewModal: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectMsgContents`, {
          projectCd: selectedData.projectCd,
          userNum: modal.data.userNum,
          msgCd: 'S',
        });
        if (res.status == 200) {
          setPreviewContents(res.data);
          setSmsPreviewOpen(true);
        }
      } catch (err) {
        showMsg('error', '에러가 발생하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * OMR 리딩결과 등록 핸들러
     */
    handleOMR: () => {
      if (omrReadRef.current) {
        omrReadRef.current.click();
      }
    },
  };

  const handleClose = () => {
    setBtnSt(null);
    setConfirmOpen(false);
  };

  return (
    <Fragment>
      <LayoutAdmin title="프로젝트 관리">
        {listView ? (
          <>
            <Stack direction="row" spacing={2}>
              {/* 프로젝트 테이블 상단 검색필터 */}
              <Stack direction="row" spacing={2}>
                <SearchCondition id="instNm" handleChange={handleChange} title={'기관명'} setData={searchInstList} />

                <SearchCondition
                  id="testKindNm"
                  handleChange={handleChange}
                  title={'진행방식'}
                  setData={searchTestKindList}
                />
              </Stack>
              {/* 프로젝트 테이블 상단 버튼 */}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {/* <SearchCondition
                  id="testTypeNm"
                  handleChange={handleChange}
                  title={'검사유형'}
                  setData={searchTestTypeList}
                /> */}
                {/* <SearchCondition
                  id="projectSt"
                  handleChange={handleChange}
                  title={'프로젝트 상태'}
                  setData={searchProjectStList}
                  val={'개시전'}
                /> */}
                <CustomButton title="프로젝트 등록" className="inlineButton" onClick={project.handleAdd} />

                <CustomButton
                  title="검사개시"
                  className={testKindNm ? 'disabledButton' : 'grayButton'}
                  onClick={() => project.handleStatus('1')}
                  disabled={testKindNm}
                />

                <CustomButton title="검사종료" className="redButton" onClick={() => project.handleStatus('2')} />
              </Stack>
            </Stack>
            {/* 프로젝트 테이블 */}
            <CustomTable
              setHeaders={projectTable.headers} // 헤더 값 지정
              setData={projectTable.datas} // 데이터 바인딩
              paging={false} // pagination 활성화 여부
              checked={true} // 테이블 check 활성화 여부
              singleCheck={true} // 단일행 체크만 가능
              handleRowClick={project.handleClick} // row 클릭 콜백
              handleRowCheck={project.handleCheck} // Checkbox 체크 콜백
              handleModify={project.handleEdit} // 체크 Drawer 수정 버튼 콜백
              handleDelete={project.handleDelete} // 체크 Drawer 삭제 버튼 콜백
            />
          </>
        ) : (
          <div className="sameSize">
            {/* 프로젝트 정보 */}
            <Box key={selectedData.id} sx={{ display: 'flex', gap: 2, margin: '10px', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  textAlign: 'right',
                }}
              >
                <span className="testInfo" style={{}}>
                  [ 검사정보 ]
                </span>
              </Box>
              <Box>
                <Box sx={{ flex: 1 }} style={{ padding: '10px' }}>
                  <div className="testInfolist">
                    <span className="testInfotitle">{projectTable.headers[1].title}</span>
                    <span className="testType">{selectedData.instNm}</span>
                    <span className="testInfotitle">{projectTable.headers[3].title}</span>
                    <span className="testType">{selectedData.jobTitle}</span>
                  </div>
                </Box>
                <Box sx={{ flex: 1 }} style={{ padding: '10px' }}>
                  <div className="testInfolist">
                    <span className="testInfotitle">{projectTable.headers[5].title}</span>
                    <span className="testType">{selectedData.testTypeNm}</span>
                    <span className="testInfotitle">{projectTable.headers[6].title}</span>
                    <span className="testType">{selectedData.testKindNm}</span>
                    <span className="testInfotitle">검사시간</span>
                    <span className="testType">{selectedData.testTm}</span>
                    <span className="testInfotitle">{projectTable.headers[10].title}</span>
                    <span className="testType">{selectedData.userCnt}</span>
                    <span className="testInfotitle">{projectTable.headers[12].title}</span>
                    <span className="testType">{selectedData.projectSt}</span>
                  </div>
                </Box>
              </Box>
            </Box>
            {/* 대상자 테이블 상단 버튼 */}
            <Stack direction="row" spacing={2}>
              {selectedData.testKindNm === '지면' && (
                <Stack direction="row" spacing={2}>
                  <SearchCondition
                    id="userNum"
                    handleChange={handleUserChange}
                    title={'수험번호'}
                    setData={searchUserNum}
                  />
                  <SearchCondition id="userNm" handleChange={handleUserChange} title={'이름'} setData={searchUserNm} />
                </Stack>
              )}

              {selectedData.testKindNm === '온라인' && (
                <Stack direction="row" spacing={2}>
                  <SearchCondition
                    id="userNum"
                    handleChange={handleUserChange}
                    title={'수험번호'}
                    setData={searchUserNum}
                  />
                  <SearchCondition id="userNm" handleChange={handleUserChange} title={'이름'} setData={searchUserNm} />
                  <SearchCondition
                    id="smsSendDt"
                    handleChange={handleUserChange}
                    title={'SMS전송상태'}
                    setData={searchSend}
                  />
                  <SearchCondition
                    id="emailSendDt"
                    handleChange={handleUserChange}
                    title={'이메일전송상태'}
                    setData={searchSend}
                  />
                  <SearchCondition
                    id="testState"
                    handleChange={handleUserChange}
                    title={'검사상태'}
                    setData={searchUserStList}
                  />
                </Stack>
              )}

              {selectedData.projectSt === '개시전' && (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {/* 등록버튼 */}
                  <CustomButton title="등록" className="inlineButton" onClick={user.handleAdd} />
                  <CustomButton title="대상자엑셀업로드" className="grayButton" onClick={user.handleUpload} />
                  <VisuallyHiddenInput type="file" onChange={user.upload} ref={uploadUserRef} />
                  {selectedData.testKindNm === '온라인' ? (
                    <>
                      <CustomButton title="e-Mail전송" className="greenButton" onClick={user.handleEmailModal} />
                      <CustomButton title="SMS전송" className="redButton" onClick={user.handleSmsModal} />
                    </>
                  ) : (
                    <>
                      <CustomButton title="OMR리딩결과등록" className="greenButton" onClick={user.handleOMR} />
                      <VisuallyHiddenInput type="file" onChange={user.omrRead} ref={omrReadRef} />
                    </>
                  )}
                </Stack>
              )}
            </Stack>

            <Dialog
              open={emailSendOpen || smsSendOpen}
              onClose={handleSendClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                sx: {
                  width: '500px',
                  maxWidth: 'lg',
                  height: '400px',
                },
              }}
            >
              <DialogContent>
                <div className="App" style={{ border: '1px solid black', padding: '20px' }}>
                  <p>
                    {emailSendOpen ? 'E-MAIL' : 'SMS'} 미전송 대상자 : {notSendCnt}명
                  </p>
                  <p>기관명 : {selectedData.instNm}</p>
                  <p>채용공고명 : {selectedData.jobTitle}</p>
                  <p>온라인 검사시작시간 : {selectedData.testFrDt}</p>
                  <p>온라인 검사종료시간 : {selectedData.testToDt}</p>
                  <p>검사시간 : {selectedData.testTm}</p>
                </div>
                {(emailSendOpen || smsSendOpen) && (
                  <div className="modalButton">
                    {emailSendOpen ? (
                      <CustomButton title="전송" className="inlineButton" onClick={user.handleEmail}></CustomButton>
                    ) : (
                      <CustomButton title="전송" className="inlineButton" onClick={user.handleSMS}></CustomButton>
                    )}

                    <CustomButton title="취소" className="redButton" onClick={handleSendClose}></CustomButton>
                  </div>
                )}
              </DialogContent>
            </Dialog>
            {/* E-MAIL, SMS 미리보기 */}
            <Dialog
              open={emailPreviewOpen || smsPreviewOpen}
              onClose={handleSendClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                sx: {
                  width: '500px',
                  maxWidth: 'lg',
                  height: '320px',
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {emailPreviewOpen && 'Email 문구'} {smsPreviewOpen && 'SMS 문구'}
              </DialogTitle>

              <DialogContent>
                <div className="App" style={{ border: '1px solid black', padding: '20px' }}>
                  <Viewer initialValue={previewContents} />
                </div>
                {(emailPreviewOpen || smsPreviewOpen) && (
                  <div className="modalButton">
                    <CustomButton title="확인" className="inlineButton" onClick={handleSendClose}></CustomButton>
                  </div>
                )}
              </DialogContent>
            </Dialog>

            {/* 대상자 테이블 */}
            <CustomTable
              setHeaders={userTable.headers} // 헤더 값 지정
              setData={userTable.datas} // 데이터 바인딩
              paging={false} // pagination 활성화 여부
              checked={selectedData.projectSt === '개시전' && true} // 테이블 check 활성화 여부
              handleRowClick={selectedData.projectSt === '개시전' && user.handleEdit} // row 클릭 콜백
              handleRowCheck={user.handleCheck} // Checkbox 체크 콜백
              handleModify={user.handleEdit} // 체크 Drawer 수정 버튼 콜백
              handleDelete={user.handleDelete} // 체크 Drawer 삭제 버튼 콜백
            />
            <Button variant="contained" className="grayButton" onClick={() => setListView(true)}>
              목록으로
            </Button>
          </div>
        )}

        <Dialog
          open={confirmOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {confirmOpen && (
                <div style={{ color: 'black', padding: '5px' }}>
                  <div>
                    선택한 {checkedData.map((data) => (data.testKindNm === '온라인' ? '온라인 ' : '지면 '))}
                    프로젝트의 상태를 <strong>{btnSt}</strong>
                    {btnSt === '검사중' ? '으로' : '로'} 변경하시겠습니까?
                  </div>
                  <div>상태를 변경하면 대상자 정보 수정이 불가합니다. </div>
                  <div>확인 후 진행해 주세요.</div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              취소
            </Button>
            <Button onClick={() => project.updateStatus(btnSt === '검사중' ? '1' : '2')} autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </LayoutAdmin>
      {/* 프로젝트 등록/수정 모달 */}
      <FormModal
        open={projectModal.open}
        handleClose={project.handleClose}
        modalTitle={projectModal.title}
        setFields={projectModal.setFields}
        setData={projectModal.data}
        addData={projectModal.addData}
        editData={projectModal.editData}
        testTypeData={projectModal.testTypeData}
      />

      {/* 대상자 등록/수정 모달 */}
      <FormModal
        open={modal.open}
        handleClose={user.handleClose}
        modalTitle={modal.title}
        setFields={modal.setFields}
        setData={modal.data}
        addData={modal.addData}
        editData={modal.editData}
        selectedData={selectedData}
        handleEmailModal={user.handleEmailPreviewModal}
        handleSmsModal={user.handleSmsPreviewModal}
      />
    </Fragment>
  );
};

export default ProjectMng;
