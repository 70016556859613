import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Header from '../containers/headers/Header';
import HeroSlider from '../containers/hero-sliders/HeroSlider';

const LayoutAdmin = ({ title, children }) => {
  return (
    <Fragment>
      <Header />
      {/* hero slider */}
      <HeroSlider title={title} />
      <div className="dg__service__area service-space section-padding--xlg bg--white">
        <div
          className="container"
          style={{
            minHeight: '500px',
          }}
        >
          {children}
        </div>
      </div>
    </Fragment>
  );
};

LayoutAdmin.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.string,
  data: PropTypes.object,
};

export default LayoutAdmin;
