import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginAdmin from './pages/LoginAdmin';
import NotFound from './pages/NotFound';

import PrivateRoute from './PrivateRoute';
import { Provider } from './common/Context';

import ProjectAdminMng from './pages/SystemAdmin/Admin/ProjectAdminMng';
import TestInfo from './pages/SystemAdmin/PersonalityTest/TestInfo';
import ProjectMng from './pages/ProjectAdmin/Project/ProjectMng';
import GuidanceMsgMng from './pages/ProjectAdmin/Project/GuidanceMsgMng';
import AssessResultMng from './pages/ProjectAdmin/AssessResult/AssessResultMng';

function App() {
  return (
    <Provider>
      <Router>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL + '/loginAdmin'}`} element={<LoginAdmin />} />
          <Route path={`${process.env.PUBLIC_URL + '/NotFound'}`} element={<NotFound />} />

          <Route element={<PrivateRoute />}>
            <Route path={`${process.env.PUBLIC_URL + '/'}`} element={<LoginAdmin />} />
            {/* 관리자 > 프로젝트관리자 관리 */}
            <Route
              path={`${process.env.PUBLIC_URL + '/SystemAdmin/Admin/ProjectAdminMng'}`}
              element={<ProjectAdminMng />}
            />
            {/* 인성검사 > 인성검사 정보 */}
            <Route
              path={`${process.env.PUBLIC_URL + '/SystemAdmin/PersonalityTest/TestInfo'}`}
              element={<TestInfo />}
            />
            {/* 프로젝트 > 프로젝트 관리 */}
            <Route path={`${process.env.PUBLIC_URL + '/ProjectAdmin/Project/ProjectMng'}`} element={<ProjectMng />} />
            {/* 프로젝트 > 안내문구 관리 */}
            <Route
              path={`${process.env.PUBLIC_URL + '/ProjectAdmin/Project/GuidanceMsgMng'}`}
              element={<GuidanceMsgMng />}
            />
            {/* 평가결과 > 평가결과 관리 */}
            <Route
              path={`${process.env.PUBLIC_URL + '/ProjectAdmin/AssessResult/AssessResultMng'}`}
              element={<AssessResultMng />}
            />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
