import React, { Fragment, useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

function sleep(duration) {
  return new Promise((resolve) => {
    // setTimeout(() => {
    resolve();
    // }, 500);
  });
}

const SearchCondition = (props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { title, setData, size, value, id, handleChange } = props;
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions(setData);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      onChange={handleChange}
      size={size}
      value={value}
      id={id}
      sx={{ width: 250 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={(option) => (typeof option === 'string' || option instanceof String ? option : option?.key)}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          id={id}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchCondition;

/**
 * 프로젝트 관리자 관리
 *  - 관리자명
 *  - 부서
 *
 * 프로젝트 관리
 *  - 기관명
 *  - 검사유형 (PAPI-A형, PAPI-B형 ...)
 *  - 진행방식 (지면, 온라인)
 *  - 프로젝트 상태 (개시전, 진행중, 검사종료)
 *
 * 대상자 관리
 *  - 수험번호
 *  - 이름
 *  - 전송상태(SMS) (미전송, 전송완료, 전송실패)
 *  - 전송상태(E-MAIL) (미전송, 전송완료, 전송실패)
 *  - 검사상태 (검사전, 검사중, 검사완료)
 *
 * 평가결과 관리
 *  - 기관명
 *  - 검사유형 (PAPI-A형, PAPI-B형 ...)
 *  - 진행방식 (지면, 온라인)
 *  - 평가결과생성 (미생성, 생성완료)
 *
 * 대상자 평가결과 관리
 *  - 수험번호
 *  - 이름
 *  - 면접대상여부
 */
