import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Fragment, useEffect, useState } from 'react';
import { useCustomContext } from '../../../common/Context';
import { CustomApi } from '../../../common/CustomApi';
import CustomButton from '../../../components/Button/CustomButton';
import SearchCondition from '../../../containers/Search/SearchCondition';
import CustomTable from '../../../containers/Table/CustomTable';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import IconButton from '@mui/material/IconButton';

/**
 * [ 평가결과 > 평가결과 관리 ]
 *
 * @returns
 */
const AssessResultMng = () => {
  const { showLoading, hideLoading } = useCustomContext();
  const [selectedData, setSelectedData] = useState(null);
  const [listView, setListView] = useState(true);
  const [interviewInfo, setInterviewInfo] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [searchInstNm, setSearchInstNm] = useState([]);
  const [searchJobTitle, setSearchJobTitle] = useState([]);
  const [searchJobField, setSearchJobField] = useState([]);
  const [searchTestKindNm, setSearchTestKindNm] = useState([]);
  const [searchUserNum, setSearchUserNum] = useState([]);
  const [searchUserNm, setSearchUserNm] = useState([]);
  const { showMsg } = useCustomContext();
  const [search, setSearch] = useState({
    instNm: '',
    jobTitle: '',
    jobField: '',
    testKindNm: '',
    createResultYn: '',
  });
  const [searchUser, setSearchUser] = useState({
    userNum: '',
    userNm: '',
    interviewStatus: '',
  });
  /**
   * 평가결과 테이블
   */
  const [assessResultTable, setAssessResultTable] = useState({
    headers: [
      { title: 'No', id: 'no', type: 'text' },
      { title: '기관명', id: 'instNm', type: 'text' },
      { title: '기관도메인명', id: 'instDomain', type: 'text' },
      { title: '채용공고명', id: 'jobTitle', type: 'text' },
      { title: '채용분야', id: 'jobField', type: 'text' },
      { title: '검사명', id: 'testTypeNm', type: 'text' },
      { title: '진행방식', id: 'testKindNm', type: 'text' },
      { title: '검사일', id: 'testDt', type: 'text' },
      { title: '검사시작일시', id: 'testFrDt', type: 'text' },
      { title: '검사종료일시', id: 'testToDt', type: 'text' },
      { title: '검사시간(분)', id: 'testTm', type: 'text' },
      { title: '대상자 인원수', id: 'userCnt', type: 'text' },
      { title: '응답문항수', id: 'questionCnt', type: 'text' },
      { title: '평가결과생성', id: 'createResultYn', type: 'text' },
    ],
    datas: [],
  });
  /**
   * 평가결과 대상자 테이블
   */
  const [userTable, setUserTable] = useState({
    headers: [
      { title: 'No', id: 'no', type: 'text' },
      { title: '수험번호', id: 'userNum', type: 'text' },
      { title: '이름', id: 'userNm', type: 'text' },
      { title: '응답문항수', id: 'questionCnt', type: 'text' },
      { title: '면접대상여부', id: 'interviewStatus', type: 'text' },
      { title: '면접번호', id: 'interviewId', type: 'text' },
    ],
    datas: [],
  });
  /**
   * 면접번호 부여/수정 모달
   */
  const [modal, setModal] = useState({
    setFields: [
      { title: 'NO.', id: 'no' },
      { title: '수험번호', id: 'userNum' },
      { title: '이름', id: 'userNm' },
      { title: '면접번호', id: 'interviewId', type: 'text' },
    ],
    title: '',
    open: false,
    data: [],
  });

  /**
   * 평가결과 목록 행 클릭 시
   * 대상자 목록 조회 함수 호출
   */
  useEffect(() => {
    !listView && user.fetchDatas();
  }, [selectedData]);
  /**
   * 대상자 화면에서 프로젝트 목록으로 이동 시
   * 선택 데이터 초기화
   */
  useEffect(() => {
    if (listView) {
      setSelectedData(null);
      setCheckedData([]);
      assessResult.fetchDatas();
    }
  }, [listView]);

  /** 평가결과 생성 검색 */
  const searchCreateResultYn = (e) => {
    return [{ key: '생성' }, { key: '미생성' }];
  };
  /** 면접대상여부 검색 */
  const searchInterviewStatus = (e) => {
    return [{ key: '대상' }, { key: '비대상' }];
  };

  useEffect(() => {
    if (listView) {
      // 프로젝트 조회
      setSelectedData(null);
      assessResult.searchResultInstNm();
      assessResult.searchResultJobTitle();
      assessResult.searchResultJobField();
      assessResult.searchResultTestKindNm();
    } else {
      // 대상자 조회
      user.searchUserNum();
      user.searchUserNm();
    }
  }, [listView]);

  useEffect(() => {
    assessResult.fetchDatas();
  }, [search]);

  useEffect(() => {
    !listView && user.fetchDatas();
  }, [searchUser]);

  const handleChange = (event, newValue) => {
    if (event.target.id) {
      setSearch((prev) => ({
        ...prev,
        [event.target.id.split('-')[0]]: newValue.key,
      }));
    } else {
      setSearch((prev) => ({
        ...prev,
        [event.currentTarget.parentElement.parentElement.firstChild.id]: '',
      }));
    }
  };

  const handleUserChange = (event, newValue) => {
    if (event.target.id) {
      setSearchUser((prev) => ({
        ...prev,
        [event.target.id.split('-')[0]]: newValue.key,
      }));
    } else {
      setSearchUser((prev) => ({
        ...prev,
        [event.currentTarget.parentElement.parentElement.firstChild.id]: '',
      }));
    }
  };

  /**
   * 평가결과 함수
   */
  const assessResult = {
    /** 기관명 검색 목록 */
    searchResultInstNm: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/AssessResultSearchList`, { type: 'instNm' });
        if (res.status == 200) {
          setSearchInstNm(res.data);
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /** 채용공고명 검색 목록 */
    searchResultJobTitle: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/AssessResultSearchList`, { type: 'jobTitle' });
        if (res.status == 200) {
          setSearchJobTitle(res.data);
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /** 채용공고명 검색 목록 */
    searchResultJobField: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/AssessResultSearchList`, { type: 'jobField' });
        if (res.status == 200) {
          setSearchJobField(res.data);
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /** 진행방색 검색 목록 */
    searchResultTestKindNm: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/AssessResultSearchList`, { type: 'testKindNm' });
        if (res.status == 200) {
          setSearchTestKindNm(res.data);
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /**
     * 평가결과 목록 조회 API
     */
    fetchDatas: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectAssessResultMng`, search);
        if (res.status === 200) {
          const data = res.data;
          setAssessResultTable((prev) => ({
            ...prev,
            datas: data,
          }));
          if (res.data.length > 0) {
            showMsg('info', '리스트가 정상적으로 조회가 되었습니다.');
          } else {
            showMsg('info', '검사완료된 프로젝트가 없습니다.');
          }
        } else {
          showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 문항반응 다운로드 API
     */
    downloadResponse: async () => {
      if (checkedData.length < 1) {
        showMsg('warning', '프로젝트를 선택해주세요');

        return;
      }
      if (checkedData.length > 1) {
        showMsg('warning', '한건의 프로젝트만 다운로드할 수 있습니다.');
        return;
      }

      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/downloadResponse`, checkedData[0], {
          responseType: 'blob',
        });
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement('a');
          a.href = url;
          const fileName = [
            checkedData[0].instDomain.slice(1),
            checkedData[0].jobTitle,
            checkedData[0].jobField,
            checkedData[0].testFrDt.length > 0 ? checkedData[0].testFrDt.split(' ')[0] : checkedData[0].testDt,
            checkedData[0].testCnt,
            '문항반응',
          ];
          a.download = fileName.join('-') + '.xlsx';
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          showMsg('success', '선택한 프로젝트의 문항반응 다운로드가 성공하였습니다.');
          assessResult.fetchDatas();
        } else {
          showMsg('error', '문항반응 다운로드가 실패하였습니다. 프로젝트를 다시 선택 해 주세요');
        }
      } catch (err) {
        showMsg('error', '문항반응 다운로드가 실패하였습니다. 프로젝트를 다시 선택 해 주세요');
      } finally {
        hideLoading();
      }
    },
    /**
     * 평가결과 생성 API
     */
    createResults: async () => {
      if (checkedData.length < 1) {
        showMsg('warning', '프로젝트를 선택해주세요');
        return;
      } else if (checkedData[0].createResultYn === '생성') {
        showMsg('warning', '이미 평가결과 생성된 프로젝트입니다.');
      } else if (checkedData[0].downResponseYn === 'N') {
        showMsg('warning', '평가결과가 생성에 실패하였습니다. 문항반응 다운로드를 다시 실행 해 주세요.');
      } else {
        try {
          showLoading();
          const res = await CustomApi.post(`/api/projectadmin/createResult`, checkedData[0]);
          if (res.status == 200) {
            assessResult.fetchDatas();
            showMsg('success', '선택한 프로젝트의 평가결과가 생성 되었습니다.');
          } else {
            showMsg('error', '평가결과가 생성에 실패하였습니다. 프로젝트를 다시 선택 해 주세요.');
          }
        } catch (err) {
          showMsg('error', '평가결과가 생성에 실패하였습니다. 프로젝트를 다시 선택 해 주세요.');
        } finally {
          hideLoading();
        }
      }
    },
    /**
     * 평가결과 테이블 클릭 핸들러
     */
    handleClick: (e) => {
      if (e.createResultYn === '미생성') {
        showMsg('warning', '평가결과생성을 한 후 대상자별 평가결과 화면으로 이동이 가능합니다.');
        return;
      }

      setListView(false);
      setSelectedData(e);
    },
    /**
     * 평가결과 테이블 체크 핸들러
     */
    handleCheck: (e) => {
      setCheckedData(
        assessResultTable.datas.filter((d) => {
          return e.some((no) => no === d.no);
        })
      );
    },
  };

  /**
   * 대상자 함수
   */
  const user = {
    /**수험번호 목록 검색 */
    searchUserNum: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/AssessResultSearchList`, {
          type: 'userNum',
          projectCd: selectedData.projectCd,
        });
        if (res.status == 200) {
          setSearchUserNum(res.data);
        } else {
          showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /**이름 목록 검색 */
    searchUserNm: async (e) => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/AssessResultSearchList`, {
          type: 'userNm',
          projectCd: selectedData.projectCd,
        });
        if (res.status == 200) {
          setSearchUserNm(res.data);
        } else {
          showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },

    /**
     * 대상자 목록 조회 API
     */
    fetchDatas: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/selectUserAssessResultMng`, {
          param: selectedData,
          ...searchUser,
        });
        if (res.status === 200) {
          const data = res.data;
          setUserTable((prev) => ({
            ...prev,
            datas: data,
          }));
          showMsg('info', '검사 대상자 리스트가 정상적으로 조회가 되었습니다.');
        } else {
          showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '검사 대상자 리스트가 정상적으로 조회되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 검사결과 다운로드 API
     */
    downloadResults: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/downloadResult`, selectedData, { responseType: 'blob' });
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement('a');
          a.href = url;
          const fileName = [
            selectedData.instDomain.slice(1),
            selectedData.jobTitle,
            selectedData.jobField,
            selectedData.testFrDt.length > 0 ? selectedData.testFrDt.split(' ')[0] : selectedData.testDt,
            selectedData.testCnt,
            '결과생성',
          ];
          a.download = fileName.join('-') + '.xlsx';
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          showMsg('success', '검사결과파일 다운로드에 성공하였습니다.');
        } else {
          showMsg('error', '검사결과파일 다운로드에 실패하였습니다.');
        }
      } catch (err) {
        showMsg('error', '검사결과파일 다운로드에 실패하였습니다.');
      } finally {
        hideLoading();
      }
    },

    /**
     * 면접번호 부여 API
     */
    updateInterviewId: async (e) => {
      e.preventDefault();
      try {
        showLoading();
        const res = await CustomApi.post(`/api/projectadmin/updateInterviewId`, {
          list: modal.data,
          projectCd: selectedData.projectCd,
          mode: modal.mode,
        });
        console.log(modal.mode);

        if (res.status === 200) {
          if (res.data === 0) {
            showMsg('warning', '반영된 데이터가 없습니다.');
            return;
          }
          if (modal.mode === 'I') {
            showMsg('success', '선택한 대상자에게 면접번호가 부여되었습니다.');
          } else {
            showMsg('success', '대상자의 면접번호 부여정보가 수정되었습니다.');
          }
          user.fetchDatas(selectedData);
          setInterviewInfo([]);
          user.handleClose();
        } else {
          showMsg('error', '선택한 대상자에게 면접번호가 부여되지 않았습니다.');
        }
      } catch (err) {
        showMsg('error', '선택한 대상자에게 면접번호가 부여되지 않았습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 기관보고서 다운로드 API
     */
    downloadInstReport: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(
          `/api/projectadmin/downloadInstReport`,
          { ...selectedData, ...searchUser },
          {
            responseType: 'blob',
          }
        );

        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = [
            selectedData.instDomain.slice(1),
            selectedData.jobTitle,
            selectedData.jobField,
            selectedData.testFrDt.length > 0 ? selectedData.testFrDt.split(' ')[0] : selectedData.testDt,
            selectedData.testCnt,
            '기관보고서',
          ];
          link.setAttribute('download', fileName.join('-') + '.zip');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          showMsg('success', '대상자 전체의 기관보고서 다운로드에 성공하였습니다.');
        } else {
          showMsg('error', '대상자 전체의 기관보고서 다운로드에 실패하습니다.');
        }
      } catch (err) {
        showMsg('error', '대상자 전체의 기관보고서 다운로드에 실패하습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 면접보고서 다운로드 API
     */
    downloadInterviewReport: async () => {
      try {
        showLoading();
        const res = await CustomApi.post(
          `/api/projectadmin/downloadInterviewReport`,
          { ...selectedData, ...searchUser },
          {
            responseType: 'blob',
          }
        );

        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/zip' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          const fileName = [
            selectedData.instDomain.slice(1),
            selectedData.jobTitle,
            selectedData.jobField,
            selectedData.testFrDt.length > 0 ? selectedData.testFrDt.split(' ')[0] : selectedData.testDt,
            selectedData.testCnt,
            '면접보고서',
          ];
          link.setAttribute('download', fileName.join('-') + '.zip');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          showMsg('success', '면접대상자 전체의 면접보고서 다운로드에 성공하였습니다.');
        } else {
          showMsg('error', '면접대상자 전체의 면접보고서 다운로드에 실패하였습니다.');
        }
      } catch (err) {
        showMsg('error', '면접대상자 전체의 면접보고서 다운로드에 실패하였습니다.');
      } finally {
        hideLoading();
      }
    },
    /**
     * 대상자 테이블 클릭 핸들러
     */
    handleClick: (e) => {
      console.log('대상자 클릭 ::: ', e);
    },
    /**
     * 대상자 테이블 체크 핸들러
     */
    handleCheck: (e) => {
      setCheckedData(
        userTable.datas.filter((d) => {
          return e.some((no) => no === d.no);
        })
      );
    },
    /**
     * 모달 열기
     */
    handleOpen: (e) => {
      if (e === 'I' && checkedData.length < 1) {
        showMsg('warning', '선택된 데이터가 없습니다.');
        return;
      }
      setModal((prev) => ({
        ...prev,
        title: e === 'I' ? '면접번호 부여' : '면접번호 수정',
        open: true,
        data: e === 'I' ? checkedData : userTable.datas.filter((data) => data.interviewStatus === '대상'),
        mode: e,
      }));
    },
    /**
     * 모달 닫기
     */
    handleClose: () => {
      setModal((prev) => ({
        ...prev,
        open: false,
        data: [],
      }));
    },
    /**
     * 면접번호 부여/수정 모달 row 삭제
     */
    handleRemove: (idx) => {
      setModal((prev) => ({
        ...prev,
        data: modal.data.filter((_, i) => i !== idx),
      }));
    },
    /**
     * 면접번호 값 변경
     */
    handleChange: (data, e) => {
      const idx = modal.data.findIndex((item) => item.no === data.no);

      const newData = [...modal.data];
      newData[idx] = { ...newData[idx], interviewId: e.target.value };

      setModal((prev) => ({
        ...prev,
        data: newData,
      }));
    },
  };

  return (
    <Fragment>
      <LayoutAdmin title="평가결과 관리">
        {listView ? (
          <div className="sameSize">
            <Stack direction="row" spacing={2}>
              {/* 평가결과 테이블 상단 검색필터 */}
              <Stack direction="row" spacing={2}>
                <SearchCondition id="instNm" handleChange={handleChange} title={'기관명'} setData={searchInstNm} />
                <SearchCondition
                  id="jobTitle"
                  handleChange={handleChange}
                  title={'채용공고명'}
                  setData={searchJobTitle}
                />
                <SearchCondition
                  id="jobField"
                  handleChange={handleChange}
                  title={'채용분야'}
                  setData={searchJobField}
                />
                <SearchCondition
                  id="testKindNm"
                  handleChange={handleChange}
                  title={'진행방식'}
                  setData={searchTestKindNm}
                />
                <SearchCondition
                  id="createResultYn"
                  handleChange={handleChange}
                  title={'평가결과생성'}
                  setData={searchCreateResultYn}
                />
              </Stack>
              {/* 평가결과 테이블 상단 버튼 */}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <CustomButton
                  title="문항반응 다운로드"
                  className="greenButton"
                  onClick={assessResult.downloadResponse}
                />
                <CustomButton title="평가결과 생성" className="grayButton" onClick={assessResult.createResults} />
              </Stack>
            </Stack>
            {/* 평가결과 테이블 */}
            <CustomTable
              setHeaders={assessResultTable.headers} // 헤더 값 지정
              setData={assessResultTable.datas} // 데이터 바인딩
              paging={false} // pagination 활성화 여부
              checked={true} // 테이블 check 활성화 여부
              singleCheck={true} // 단일행 체크만 가능
              handleRowClick={assessResult.handleClick} // row 클릭 콜백
              handleRowCheck={assessResult.handleCheck} // Checkbox 체크 콜백
            />
          </div>
        ) : (
          <div className="sameSize">
            {/* 프로젝트 정보 */}
            <div className="testInfolist">
              <span className="testInfo">[ 검사정보 ]</span>
              <span className="testInfotitle">{assessResultTable.headers[1].title} </span>
              <span className="testType">{selectedData.instNm}</span>
              <span className="testInfotitle">{assessResultTable.headers[3].title}</span>
              <span className="testType">{selectedData.jobTitle}</span>
              <span className="testInfotitle">{assessResultTable.headers[5].title}</span>
              <span className="testType">{selectedData.testTypeNm}</span>
              <span className="testInfotitle">{assessResultTable.headers[6].title}</span>
              <span className="testType">{selectedData.testKindNm}</span>
              <span className="testInfotitle">{assessResultTable.headers[11].title}</span>
              <span className="testType">{selectedData.userCnt}</span>
            </div>

            <Stack direction="row" spacing={2}>
              {/* 대상자 테이블 상단 검색필터 */}
              <Stack direction="row" spacing={2}>
                <SearchCondition
                  id="userNum"
                  handleChange={handleUserChange}
                  title={'수험번호'}
                  setData={searchUserNum}
                />
                <SearchCondition id="userNm" handleChange={handleUserChange} title={'이름'} setData={searchUserNm} />
                <SearchCondition
                  id="interviewStatus"
                  handleChange={handleUserChange}
                  title={'면접대상여부'}
                  setData={searchInterviewStatus}
                />
              </Stack>
              {/* 대상자 테이블 상단 버튼 */}
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <CustomButton title="검사결과 다운로드" className="greenButton" onClick={user.downloadResults} />
                {userTable.datas.find((d) => d.interviewStatus === '대상') ? (
                  <CustomButton
                    title="면접번호수정"
                    className="grayButton"
                    onClick={() => {
                      user.handleOpen('U');
                    }}
                  />
                ) : (
                  <CustomButton
                    title="면접번호부여"
                    className="grayButton"
                    onClick={() => {
                      user.handleOpen('I');
                    }}
                  />
                )}

                <CustomButton title="기관보고서 다운" className="redButton" onClick={user.downloadInstReport} />
                <CustomButton title="면접보고서 다운" className="inlineButton" onClick={user.downloadInterviewReport} />
              </Stack>
            </Stack>
            {/* 대상자 테이블 */}
            <CustomTable
              setHeaders={userTable.headers} // 헤더 값 지정
              setData={userTable.datas} // 데이터 바인딩
              paging={false} // pagination 활성화 여부
              checked={true} // 테이블 check 활성화 여부
              handleRowCheck={user.handleCheck} // Checkbox 체크 콜백
              onButtonClick={() => user.handleOpen('I')}
            />

            <Button variant="contained" className="grayButton" onClick={() => setListView(true)}>
              목록으로
            </Button>
          </div>
        )}
      </LayoutAdmin>
      {/* 면접번호 부여/수정 모달 */}
      <div>
        <Modal
          open={modal.open}
          onClose={user.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="boxStyle" style={{ width: '500px', height: '490px' }}>
            <Typography className="modalTitle" id="modal-modal-title" variant="h6" component="h2">
              {modal.title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ margin: '0 auto' }}>
              <form onSubmit={user.updateInterviewId}>
                <div style={{ border: 'none', width: '468px', height: '330px', overflowY: 'scroll' }}>
                  <table>
                    <thead className="theadStyle">
                      <tr>
                        {modal.setFields.map((data) => (
                          <th key={data.title} style={{ border: '1px solid white', padding: '8px' }}>
                            {data.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="tbodyStyle">
                      {modal.data.length > 0 &&
                        modal.data.map((data, index) => (
                          <tr className="tableStyle" key={index}>
                            <td className="table-cell">{data.no}</td>
                            <td className="table-cell">{data.userNum}</td>
                            <td className="table-cell">{data.userNm}</td>
                            <td className="table-cell">
                              <TextField
                                color="action"
                                style={{ width: '150px' }}
                                label="면접번호 입력"
                                id={`outlined-size-small-${data.no}`}
                                size="small"
                                onChange={(e) => user.handleChange(data, e)}
                                autoComplete="off"
                                value={data.interviewId}
                                required
                              />
                            </td>
                            <td>
                              <IconButton onClick={() => user.handleRemove(index)}>
                                <ClearIcon />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="modalButton">
                  <Button type="submit" className="submitButton" variant="contained">
                    저장
                  </Button>

                  <Button className="cancleButton" onClick={user.handleClose} color="error" variant="contained">
                    취소
                  </Button>
                </div>
              </form>
            </Typography>
          </Box>
        </Modal>
      </div>
    </Fragment>
  );
};

export default AssessResultMng;
