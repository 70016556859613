import React from 'react';
import { Link } from 'react-router-dom';

const MobileNavMenu = () => {
  return (
    <nav className="offcanvasNavigation" id="offcanvas-navigation">
      <ul>
        <li className="menuItemHasChildren">
          <Link to={process.env.PUBLIC_URL + '/SystemAdmin/Admin/ProjectAdminMng'}>관리자</Link>
          <ul className="subMenu">
            <li>
              <Link to={process.env.PUBLIC_URL + '/SystemAdmin/Admin/ProjectAdminMng'}>프로젝트 관리자 관리</Link>
            </li>
          </ul>
        </li>

        <li className="menuItemHasChildren">
          <Link to={process.env.PUBLIC_URL + '/SystemAdmin/PersonalityTest/TestInfo'}>인성검사</Link>
          <ul className="subMenu">
            <li>
              <Link to={process.env.PUBLIC_URL + '/SystemAdmin/PersonalityTest/TestInfo'}>인성검사 정보</Link>
            </li>
          </ul>
        </li>

        <li className="menuItemHasChildren">
          <Link to={process.env.PUBLIC_URL + '/ProjectAdmin/Project/ProjectMng'}>프로젝트</Link>
          <ul className="subMenu">
            <li>
              <Link to={process.env.PUBLIC_URL + '/ProjectAdmin/Project/ProjectMng'}>프로젝트 관리</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/ProjectAdmin/Project/GuidanceMsgMng'}>안내문구 관리</Link>
            </li>
          </ul>
        </li>
        <li className="menuItemHasChildren">
          <Link to={process.env.PUBLIC_URL + '/ProjectAdmin/AssessResult/AssessResultMng'}>평가결과</Link>
          <ul className="subMenu">
            <li>
              <Link to={process.env.PUBLIC_URL + '/ProjectAdmin/AssessResult/AssessResultMng'}>평가결과 관리</Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
