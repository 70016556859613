import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { useCustomContext } from '../../common/Context';
import { ConstructionOutlined } from '@mui/icons-material';

const CustomTable = (props) => {
  const {
    setHeaderGroup,
    setHeaders,
    setData,
    paging,
    checked,
    singleCheck,
    groupBy,
    groupHeaders,
    handleRowClick,
    handleRowCheck,
    handleModify,
    handleDelete,
    onButtonClick,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9999);
  const [rows, setRows] = useState(setData);
  const [drawer, setDrawer] = useState(false);
  const { showMsg } = useCustomContext();

  useEffect(() => {
    if (!paging) {
      setRowsPerPage(setData.length);
    }
  }, [props]);

  useEffect(() => {
    setSelected([]);
    setRows(setData);
  }, [setData]);

  useEffect(() => {
    setRows(
      stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    if (handleRowCheck && selected) {
      setDrawer(selected.length > 0);
      handleRowCheck(selected);
    }
  }, [selected]);

  let prevValue;
  const isSamePrev = (data) => {
    return prevValue == data[groupBy];
  };

  const calcRowspan = (data) => {
    prevValue = data[groupBy];
    return rows.filter((d) => d[groupBy] === data[groupBy]).length;
  };

  /**
   * Drawer 버튼 클릭 이벤트
   * @param {*} e
   */
  const handleDrawerClick = (e) => {
    const checkedRows = setData.filter((d) => selected.includes(d.no));

    if (e.currentTarget.id == 'delete') {
      console.log('checkedRows ::: ', checkedRows);
      handleDelete(checkedRows);
    } else if (e.currentTarget.id == 'modify') {
      if (checkedRows[0].userCnt && checkedRows[0].userCnt.split(' / ')[0] > 0) {
        showMsg('error', ' 대상자가 등록되거나 검사중 or 검사종료된 프로젝트는 수정 불가합니다.');
        return;
      }
      handleModify(checkedRows);
    }
  };

  /**
   * 전체 체크 이벤트
   * @param {*} event
   * @returns
   */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.filter((d) => !d.interviewId).map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  /**
   *
   * @param {*} event
   * @param {*} no
   */
  const handleClick = (event, no) => {
    const tagName = event.target.tagName;
    /* 체크박스 클릭 시 */
    if (event.target.checked !== undefined) {
      const selectedIndex = selected.indexOf(no);
      let newSelected = [];

      if (singleCheck) {
        if (selectedIndex == -1) {
          newSelected = [no];
        } else {
          newSelected = [];
        }
        setSelected(newSelected);
      } else {
        if (selectedIndex == -1) {
          newSelected = newSelected.concat(selected, no);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
      }
    } else if (tagName === 'BUTTON' || tagName === 'svg' || tagName === 'path') {
    } else {
      handleRowClick
        ? handleRowClick(
            setData.find((d) => d.no === no),
            event
          )
        : event.stopPropagation();
    }
  };

  /**
   * 페이지 이동
   * @param {*} event
   * @param {*} newPage
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * 한 페이지 당 표시 데이터 수 변경
   * @param {*} event
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * 렌더링 시 해당 row 체크상태 확인
   * @param {*} no
   * @returns
   */
  const isSelected = (no) => selected.indexOf(no) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function CustomTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className="thead">
        {setHeaderGroup && (
          <TableRow>
            {setHeaderGroup.map((headCell) => {
              return (
                <TableCell key={headCell.id} align={'center'} padding={'normal'} colSpan={headCell.colspan}>
                  <span>{headCell.title}</span>
                </TableCell>
              );
            })}
          </TableRow>
        )}

        <TableRow>
          {checked && (
            <TableCell padding="checkbox">
              {!singleCheck && (
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              )}
            </TableCell>
          )}

          {!setHeaderGroup &&
            setHeaders.map((headCell) => {
              return (
                <TableCell
                  key={headCell.id}
                  align={'center'}
                  padding={'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {groupBy ? (
                    <span>{headCell.title}</span>
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.title}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  )}
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }

  CustomTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  return (
    <Box sx={{ width: '90%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer style={{ maxHeight: 600 }}>
          <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <CustomTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.no);
                const labelId = `enhanced-table-checkbox-${index}`;
                const isItemSamePrev = isSamePrev(row);
                const rowspanCnt = !groupBy || isSamePrev(row) ? 1 : calcRowspan(row);

                return (
                  <>
                    {groupBy && groupHeaders && !isItemSamePrev && (
                      <TableRow
                        tabIndex={-1}
                        key={'group' + row.no}
                        className={'groupHeader'}
                        sx={{ cursor: 'default' }}
                      >
                        {groupHeaders.map((cell) => {
                          return (
                            <TableCell
                              key={cell.id}
                              align={cell.align || 'center'}
                              rowSpan={cell.id == groupBy ? rowspanCnt + 1 : 1}
                            >
                              {cell.type == 'data' ? row[cell.title] : cell.title}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )}
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.no)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.no}
                      className={row.type == 'A' ? 'areaRow' : ''}
                      selected={isItemSelected}
                      sx={{ cursor: handleRowClick ? 'pointer' : 'default' }}
                    >
                      {checked ? (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            disabled={row?.interviewId}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      ) : (
                        <></>
                      )}

                      {setHeaders.map((cell) => {
                        return (
                          <>
                            {(!groupBy || groupBy != cell.id || (!groupHeaders && !isItemSamePrev)) && (
                              <TableCell
                                key={cell.id}
                                align={cell.align || 'center'}
                                rowSpan={cell.id == groupBy ? rowspanCnt : 1}
                              >
                                {cell.type === 'imageLabel' ? (
                                  <div className="rowState">
                                    <Button
                                      className={cell.imageLabelClassName(row[cell.id])}
                                      size="small"
                                      variant="contained"
                                    >
                                      {row[cell.id]}
                                    </Button>
                                  </div>
                                ) : typeof row[cell.id] === 'number' && !Number.isInteger(row[cell.id]) ? (
                                  row[cell.id].toFixed(3)
                                ) : (
                                  row[cell.id]
                                )}
                              </TableCell>
                            )}
                          </>
                        );
                      })}
                    </TableRow>
                  </>
                );
              })}
              {rows.length == 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={setHeaders.length + checked} align={'center'}>
                    데이터가 없습니다.
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paging ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <></>
        )}
      </Paper>

      {(handleModify || handleDelete || onButtonClick) && (
        <Drawer
          sx={{
            flexShrink: 0,
          }}
          variant="persistent"
          anchor="bottom"
          open={drawer}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              backgroundColor: '#353535',
              padding: '15px',
            }}
          >
            <Stack direction="row" spacing={2}>
              {onButtonClick && (
                <Button
                  id="modify"
                  variant="contained"
                  color="inherit"
                  size="large"
                  startIcon={<EditIcon />}
                  onClick={onButtonClick}
                >
                  면접번호부여
                </Button>
              )}
              {handleModify && selected.length <= 1 && (
                <Button
                  id="modify"
                  variant="contained"
                  color="inherit"
                  size="large"
                  startIcon={<EditIcon />}
                  onClick={handleDrawerClick}
                >
                  수정
                </Button>
              )}
              {handleDelete && (
                <Button
                  id="delete"
                  variant="contained"
                  color="error"
                  size="large"
                  startIcon={<DeleteIcon />}
                  onClick={handleDrawerClick}
                >
                  삭제
                </Button>
              )}
            </Stack>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default CustomTable;
