import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Logo from '../../components/header/Logo';
import Navigation from '../../components/header/Navigation';
import HeaderBtn from '../../components/header/HeaderBtn';
import MobileMenu from '../../components/header/MobileMenu';

import { CustomApi } from '../../common/CustomApi';

const Header = ({ theme }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [logoImage, setLogoImage] = useState(null);
  const [menuList, setMenuList] = useState(null);

  useEffect(() => {
    const header = document.querySelector('header');
    setHeaderTop(header.offsetTop);
    window.addEventListener('scroll', handleScroll);

    setLogoImage(process.env.PUBLIC_URL + '/images/logo/assessday_logo.png');
    getMenuList();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const getMenuList = async () => {
    try {
      const res = await CustomApi.post(`/api/common/selectMenuList`, { loginId: localStorage.getItem('loginId') });
      if (res.status == 200) {
        setMenuList(res.data);
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <header className={`dg__header header--absolute space-right-left ${scroll > headerTop ? 'stick' : ''}`}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-2 col-xl-2 col-6">
            {/* logo */}
            <Logo image={logoImage} />
          </div>
          {localStorage.getItem('loginId') && (
            <>
              <div className="col-lg-7 col-xl-8 d-none d-lg-block">
                {/* navigation */}
                <Navigation menuList={menuList} />
              </div>
              <div className="col-lg-3 col-xl-2 col-6">
                {/* header buttons */}
                <HeaderBtn />
              </div>
            </>
          )}
        </div>
      </div>
      {/* Mobile Menu */}
      <MobileMenu />
    </header>
  );
};

Header.propTypes = {
  theme: PropTypes.string,
};

export default Header;
