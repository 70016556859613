import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

const Navigation = ({ menuList }) => {
  return (
    <nav className="mainmenu__nav">
      <ul className="mainmenu">
        {menuList &&
          menuList.map(
            (row, idx) =>
              row.ordr === 1 && (
                <li key={idx} className="drop">
                  <Link to={`${process.env.PUBLIC_URL + row.menuUrl}`}>
                    {row.upperMenuNm} <IoIosArrowDown />
                  </Link>
                  <ul className="dropdown">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL + row.menuUrl}`}>{row.menuNm}</Link>
                    </li>
                    {menuList
                      .filter((d) => d.ordr === 2)
                      .map(
                        (data) =>
                          row.upperMenuNm === data.upperMenuNm && (
                            <li>
                              <Link to={`${process.env.PUBLIC_URL + data.menuUrl}`}>{data.menuNm}</Link>
                            </li>
                          )
                      )}
                  </ul>
                </li>
              )
          )}
      </ul>
    </nav>
  );
};

export default Navigation;
