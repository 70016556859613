import React, { Fragment, useEffect, useState, useRef } from 'react';

import Stack from '@mui/material/Stack';
import CustomButton from '../../../components/Button/CustomButton';
import CustomTable from '../../../containers/Table/CustomTable';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import FormModal from '../../../components/modal/FormModal';

import { CustomApi } from '../../../common/CustomApi';
import { useCustomContext } from '../../../common/Context';

/**
 * [ 관리자 > 프로젝트 관리자 관리 ]
 *
 * @returns
 */
const ProjectAdminMng = () => {
  const { showLoading, hideLoading } = useCustomContext();
  const { showMsg } = useCustomContext();
  const [table, setTable] = useState({
    headers: [
      { title: 'No', id: 'no', type: 'text' },
      { title: '관리자명', id: 'userNm', type: 'text' },
      { title: '부서', id: 'userDept', type: 'text' },
      { title: '직위', id: 'userPosition', type: 'text' },
      { title: 'ID', id: 'userId', type: 'text' },
      { title: '권한구분', id: 'authGroupNm', type: 'text' },
    ],
    datas: [],
  });

  const [modal, setModal] = useState({
    setFields: [
      { title: '관리자명', id: 'userNm', type: 'text', required: true },
      { title: '부서', id: 'userDept', type: 'text' },
      { title: '직위', id: 'userPosition', type: 'text' },
      { title: 'ID', id: 'userId', type: 'text', required: true, disabled: true },
      { title: 'PW', id: 'userPw', type: 'password', required: true, readOnly: true },
    ],
    title: '',
    open: false,
    data: {},
    addData: '',
    editData: '',
  });

  useEffect(() => {
    fetchDatas();
  }, []);

  /**
   * 데이터 조회
   */
  const fetchDatas = async () => {
    try {
      showLoading();
      const res = await CustomApi.post(`/api/systemadmin/selectProjectAdmin`);
      if (res.status == 200) {
        setTable((prev) => ({
          ...prev,
          datas: res.data,
        }));
        showMsg('info', '리스트가 정상적으로 조회 되었습니다.');
      } else {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      }
    } catch (err) {
      showMsg('error', '에러가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  /**
   * 데이터 입력
   */
  const addData = async (e) => {
    try {
      showLoading();
      const res = await CustomApi.post(`/api/systemadmin/insertProjectAdmin`, e);
      if (res.status == 200) {
        fetchDatas();
        handleModalClose();
        showMsg('success', '입력한 정보가 등록 되었습니다.');
      } else {
        showMsg('error', '에러가 발생하였습니다.');
      }
    } catch (err) {
      showMsg('error', '에러가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  /**
   * 데이터 수정
   * @param {*} e
   */
  const editData = async (e) => {
    try {
      showLoading();
      const res = await CustomApi.post(`/api/systemadmin/updateProjectAdmin`, e);
      if (res.status == 200) {
        fetchDatas();
        handleModalClose();
        showMsg('success', '입력한 정보가 수정 되었습니다.');
      } else {
        showMsg('error', '에러가 발생하였습니다.');
      }
    } catch (err) {
      showMsg('error', '에러가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  /**
   * 데이터 삭제
   * @param {*} e
   */
  const deleteData = async (e) => {
    try {
      showLoading();
      const res = await CustomApi.post(`/api/systemadmin/deleteProjectAdmin`, { list: e });
      if (res.status == 200) {
        if (res.data === 'authGroupNm') {
          showMsg('error', '시스템관리자는 삭제할 수 없습니다.');
        } else if (res.data === 'projectAdmin') {
          showMsg(
            'error',
            '선택한 프로젝트 관리자가 등록한 프로젝트가 있어 삭제할 수 없습니다. 삭제할 프로젝트 관리자를 다시 선택해주세요.'
          );
        } else {
          fetchDatas();
          showMsg('success', '선택한 프로젝트 관리자가 삭제 되었습니다.');
        }
      } else {
        showMsg('error', '에러가 발생하였습니다.');
      }
    } catch (err) {
      showMsg('error', '에러가 발생하였습니다.');
    } finally {
      hideLoading();
    }
  };

  /**
   * 프로젝트 관리자 등록 버튼
   */
  const projectAdd = (e) => {
    setModal((prev) => ({
      ...prev,
      title: '프로젝트 관리자 등록',
      open: true,
      data: {},
      addData: addData,
    }));
  };

  /**
   * 모달 닫기
   */
  const handleModalClose = (e) => {
    setModal((prev) => ({
      ...prev,
      open: false,
      data: {},
      addData: '',
      editData: '',
    }));
  };

  /**
   * 테이블 클릭 이벤트
   * @param {*} e
   */
  function handleClick(row) {
    handleModify(row);
  }

  /**
   * 체크박스 체크 이벤트
   * @param {*} e
   */
  const handleCheck = (e) => {
    console.log('handleCheck >> ', e);
  };

  /**
   * 데이터 수정
   * @param {*} e
   */
  const handleModify = (e) => {
    setModal((prev) => ({
      ...prev,
      title: '프로젝트 관리자 수정',
      open: true,
      data: Array.isArray(e) ? e[0] : e,
      editData: editData,
    }));
  };

  /**
   * 데이터 삭제
   * @param {*} e
   */
  const handleDelete = (e) => {
    deleteData(e);
  };

  return (
    <Fragment>
      <LayoutAdmin title="프로젝트 관리자 관리">
        {/* 테이블 상단 검색필터/버튼 영역 */}
        <Stack direction="row" spacing={2}>
          {/* 상단 버튼 */}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CustomButton title="등록" className="inlineButton" onClick={projectAdd} />
          </Stack>
        </Stack>

        <CustomTable
          setHeaders={table.headers}
          setData={table.datas}
          paging={false} // pagination 활성화 여부
          checked={true} // 테이블 check 활성화 여부
          handleRowClick={handleClick} // row 클릭 콜백
          handleRowCheck={handleCheck} // Checkbox 체크 콜백
          handleModify={handleModify} // 체크 Drawer 수정 버튼 콜백
          handleDelete={handleDelete} // 체크 Drawer 삭제 버튼 콜백
        />
      </LayoutAdmin>

      <FormModal
        open={modal.open}
        handleClose={handleModalClose}
        modalTitle={modal.title}
        setFields={modal.setFields}
        setData={modal.data}
        addData={modal.addData}
        editData={modal.editData}
      ></FormModal>
    </Fragment>
  );
};

export default ProjectAdminMng;
