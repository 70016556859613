import React, { Fragment, useState, useEffect } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import CustomTable from '../../../containers/Table/CustomTable';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import Button from '@mui/material/Button';

import { CustomApi } from '../../../common/CustomApi';
import { useCustomContext } from '../../../common/Context';
/**
 * [ 인성검사 > 인성검사 정보 ]
 *
 * @returns
 */
const TestInfo = () => {
  const { showLoading, hideLoading } = useCustomContext();

  const [listView, setListView] = useState(true);
  const [tabValue, setTabValue] = useState('1');
  const [selectedData, setSelectedData] = useState(null);
  const { showMsg } = useCustomContext();

  const [list, setList] = useState({
    headers: [
      { title: 'No', id: 'no', type: 'text' },
      { title: '검사명', id: 'testTypeNm', type: 'text' },
      { title: '총문항수', id: 'questionCnt', type: 'text' },
      { title: '검사시간(분)', id: 'testTm', type: 'text' },
      { title: '진행방식', id: 'testKindNm', type: 'text' },
    ],
    datas: [],
  });

  const [tab, setTab] = useState({
    questionInfoHeaders: [
      { title: 'No', id: 'no', type: 'text' },
      { title: '문항', id: 'questionNm', type: 'text' },
      { title: '영역', id: 'areaNm', type: 'text' },
      { title: '요인', id: 'factorNm', type: 'text' },
      { title: '하위요인', id: 'subfactorNm', type: 'text' },
      { title: '문항코드', id: 'questionCd', type: 'text' },
      { title: '역문항', id: 'reverseYn', type: 'text' },
    ],
    averageInfoHeaders: [
      { title: '영역별 요인', id: 'factorNm', type: 'text' },
      { title: '평균', id: 'avgVal', type: 'text' },
      { title: '표준편차', id: 'sdVal', type: 'text' },
    ],
    standardInfoHeaders: [
      { title: 'T점수', id: 'tscore', type: 'text' },
      { title: '개방성', id: 'score1', type: 'text' },
      { title: '대인지향', id: 'score2', type: 'text' },
      { title: '성실성', id: 'score3', type: 'text' },
      { title: '조직지향', id: 'score4', type: 'text' },
      { title: '적극성', id: 'score5', type: 'text' },
      { title: '윤리성', id: 'score6', type: 'text' },
      { title: '적격인성종합', id: 'score7', type: 'text' },
      { title: '스트레스취약성', id: 'score8', type: 'text' },
      { title: '대인불신', id: 'score9', type: 'text' },
      { title: '반사회성', id: 'score10', type: 'text' },
      { title: '편집증', id: 'score11', type: 'text' },
      { title: '공격성', id: 'score12', type: 'text' },
      { title: '조직부적응성종합', id: 'score13', type: 'text' },
      { title: '사회적바람직성종합', id: 'score14', type: 'text' },
    ],
    overallInfoHeaderGroup: [
      { title: '적격성 평가 결과', colspan: '5' },
      { title: '평가 결과 코멘트', colspan: '2' },
    ],
    overallInfoHeaders: [
      { title: '', id: 'overallNm', type: 'text' },
      { title: '', id: 'factorRs1', type: 'text' },
      { title: '', id: 'factorRs2', type: 'text' },
      { title: '', id: 'factorRs3', type: 'text' },
      { title: '', id: 'overallRs', type: 'text' },
      { title: '', id: 'overallCd', type: 'text' },
      { title: '', id: 'overallCmt', type: 'text' },
    ],
    overallInfoGroupHeaders: [
      { title: 'overallNm', id: 'overallNm', type: 'data' },
      { title: 'factorNm1', id: 'factorRs1', type: 'data' },
      { title: 'factorNm2', id: 'factorRs2', type: 'data' },
      { title: 'factorNm3', id: 'factorRs3', type: 'data' },
      { title: '', id: 'overallRs', type: 'text' },
      { title: '코멘트코드', id: 'overallCd', type: 'text' },
      { title: '코멘트', id: 'overallCmt', type: 'text' },
    ],
    factorInfoHeaders: [
      { title: '영역', id: 'areaNm', type: 'text' },
      { title: '요인', id: 'factorNm', type: 'text' },
      { title: '정의', id: 'factorDef', type: 'text' },
    ],
    datas: [],
  });

  useEffect(() => {
    fetchListDatas();
  }, []);

  useEffect(() => {
    if (listView) {
      setSelectedData(null);
    }
  }, [listView]);

  useEffect(() => {
    handleTabChange('', '1');
  }, [selectedData]);

  /**
   * 목록 데이터 조회
   */
  const fetchListDatas = async () => {
    try {
      showLoading();
      const res = await CustomApi.post(`/api/systemadmin/selectTestInfo`);
      if (res.status == 200) {
        setList((prev) => ({
          ...prev,
          datas: res.data,
        }));
        showMsg('info', '리스트가 정상적으로 조회가 되었습니다.');
      } else {
        showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
      }
    } catch (err) {
      showMsg('error', '리스트가 정상적으로 조회되지 않았습니다.');
    } finally {
      hideLoading();
    }
  };

  /**
   * 탭 데이터 조회
   */
  const fetchTabDatas = async (url, tabVal) => {
    let successMsg;
    let errorMsg;

    try {
      showLoading();
      const res = await CustomApi.post(url, selectedData);

      switch (tabVal) {
        case '1':
          successMsg = '문항정보가 정상적으로 조회가 되었습니다.';
          errorMsg = '문항정보가 정상적으로 조회되지 않았습니다.';
          break;
        case '2':
          successMsg = '평균 및 표준편차 정보가 정상적으로 조회가 되었습니다.';
          errorMsg = '평균 및 표준편차 정보가 정상적으로 조회되지 않았습니다.';
          break;
        case '3':
          successMsg = '규준표 정보가 정상적으로 조회가 되었습니다.';
          errorMsg = '규준표 정보가 정상적으로 조회되지 않았습니다.';
          break;
        case '4':
          successMsg = '총평문구 정보가 정상적으로 조회가 되었습니다.';
          errorMsg = '총평문구 정보가 정상적으로 조회되지 않았습니다.';
          break;
        case '5':
          successMsg = '요인정의 정보가 정상적으로 조회가 되었습니다.';
          errorMsg = '요인정의 정보가 정상적으로 조회되지 않았습니다.';
          break;
        default:
          break;
      }

      if (res.status == 200) {
        setTab((prev) => ({
          ...prev,
          datas: res.data,
        }));

        showMsg('info', successMsg);
      } else {
        showMsg('error', errorMsg);
      }
    } catch (err) {
      showMsg('error', errorMsg);
    } finally {
      hideLoading();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (listView) return;

    let url = '';
    switch (newValue) {
      case '1':
        url = '/api/systemadmin/selectQuestionInfo';
        break;
      case '2':
        url = '/api/systemadmin/selectAverageInfo';
        break;
      case '3':
        url = '/api/systemadmin/selectStandardInfo';
        break;
      case '4':
        url = '/api/systemadmin/selectOverallInfo';
        break;
      case '5':
        url = '/api/systemadmin/selectFactorInfo';
        break;
      default:
        break;
    }
    fetchTabDatas(url, newValue);
  };

  const handleClick = (row) => {
    setListView(false);
    setSelectedData(row);
  };

  return (
    <>
      <Fragment>
        <LayoutAdmin title="인성검사 정보">
          {listView ? (
            <CustomTable
              setHeaders={list.headers}
              setData={list.datas}
              paging={false} // pagination 활성화 여부
              checked={false} // 테이블 check 활성화 여부
              handleRowClick={handleClick} // row 클릭 콜백
              onClick={() => handleClick}
            />
          ) : (
            <div>
              <div className="testInfolist">
                <span className="testInfo">[ 검사정보 ]</span>
                {list.headers.map((header) => (
                  <span key={header.id}>
                    <span className="testInfotitle">{header.title}</span>
                    <span className="testType">{selectedData[header.id]}</span>
                  </span>
                ))}
              </div>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="문항정보" value="1" />
                    <Tab label="평균 및 표준편차" value="2" />
                    <Tab label="규준표" value="3" />
                    <Tab label="총평문구" value="4" />
                    <Tab label="요인정의" value="5" />
                  </TabList>
                </Box>
                {/* 문항정보 */}
                <TabPanel className="tabContext" value="1">
                  <CustomTable setHeaders={tab.questionInfoHeaders} setData={tab.datas} />
                </TabPanel>
                {/* 평균 및 표준편차 */}
                <TabPanel value="2">
                  <CustomTable setHeaders={tab.averageInfoHeaders} setData={tab.datas} />
                </TabPanel>
                {/* 규준표 */}
                <TabPanel value="3">
                  <CustomTable setHeaders={tab.standardInfoHeaders} setData={tab.datas} />
                </TabPanel>
                {/* 총평문구 */}
                <TabPanel value="4">
                  <CustomTable
                    setHeaderGroup={tab.overallInfoHeaderGroup}
                    setHeaders={tab.overallInfoHeaders}
                    setData={tab.datas}
                    groupBy={'overallNm'}
                    groupHeaders={tab.overallInfoGroupHeaders}
                  />
                </TabPanel>
                {/* 요인정의 */}
                <TabPanel value="5">
                  <CustomTable setHeaders={tab.factorInfoHeaders} setData={tab.datas} />
                </TabPanel>
              </TabContext>
              <Button variant="contained" className="grayButton" onClick={() => setListView(true)}>
                목록으로
              </Button>
            </div>
          )}
        </LayoutAdmin>
      </Fragment>
    </>
  );
};

export default TestInfo;
