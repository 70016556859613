import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useCustomContext } from './common/Context';

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { showLoading, hideLoading } = useCustomContext();

  useEffect(() => {
    const token = localStorage.getItem('admin-accessToken');
    setIsAuthenticated(!!token);
    setIsLoading(false); // 로딩 완료 설정
  }, []);

  if (isLoading) {
    showLoading();
  } else {
    hideLoading();
    return isAuthenticated ? <Outlet /> : <Navigate to="/loginAdmin" />;
  }
};

export default PrivateRoute;
