import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import Stack from '@mui/material/Stack';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import CustomButton from '../../../components/Button/CustomButton';

import { useCustomContext } from '../../../common/Context';
import { CustomApi } from '../../../common/CustomApi';

/**
 * [ 프로젝트 > 안내문구 관리 ]
 *
 * @returns
 */
const GuidanceMsgMng = () => {
  const { showLoading, hideLoading } = useCustomContext();
  const { showMsg } = useCustomContext();

  const editorRef = useRef();

  const [tabValue, setTabValue] = useState('E');
  const [inputByte, setInputByte] = useState(0);
  const [maxByte, setMaxByte] = useState(0);

  useEffect(() => {
    fetchDatas();
  }, []);

  useEffect(() => {
    fetchDatas();
    setMaxByte(tabValue === 'E' ? 10000 : 2000);
  }, [tabValue]);

  /**
   * 탭 변경 핸들러
   */
  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  /**
   * Editor key 입력 핸들러
   */
  const handleKeydown = (type, e) => {
    if (e.key === '`') {
      showMsg('warning', '` 문자는 입력할 수 없습니다.');
      e.preventDefault();
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      const editorText = editorRef.current.getInstance().getMarkdown().split('\n');
      const selection = editorRef.current.getInstance().getSelection();

      const startLine = selection[0][0] - 1; // drag 시작 라인
      const startIndex = selection[0][1] - 1; // drag 시작 인덱스
      const endLine = selection[1][0] - 1; // drag 종료 라인
      const endIndex = selection[1][1] - 1; // drag 종료 인덱스

      // 주어진 문자열에서 특정 값을 찾는 함수
      const findValueIndices = (lines, value) => {
        const indices = [];
        lines.forEach((line, lineIndex) => {
          let idx = 0;
          while ((idx = line.indexOf(value, idx)) !== -1) {
            indices.push([
              [lineIndex, idx - 1],
              [lineIndex, idx + value.length],
            ]);
            idx += value.length; // 다음 검색을 위해 startIndex를 업데이트
          }
        });
        return indices;
      };

      // btnByte의 value에 해당하는 문자열 위치
      const result = btnByte.map((item) => findValueIndices(editorText, item.value)).flat();

      let replaceStart = startIndex;
      let replaceEnd = endIndex;
      // 찾은 문자열 위치와 현재 커서 위치를 비교하여 포함되는지 확인
      result.filter((d) => {
        const [[startL, startI], [endL, endI]] = d;

        if (startLine === endLine && startIndex === endIndex) {
          if (
            startL === startLine &&
            startI <= startIndex - (e.key === 'Backspace') &&
            endI >= startIndex - (e.key === 'Backspace')
          ) {
            replaceStart = startI;
            replaceEnd = endI + 1;
          }
        } else {
          if (startL === startLine && startI <= startIndex - (e.key === 'Backspace') && endI >= startIndex) {
            replaceStart = startI;
          }

          if (endL === endLine && startI <= endIndex - 1 && endI >= endIndex - (e.key === 'Backspace')) {
            replaceEnd = endI + 1;
          }
        }
      });

      let newText = editorText;
      // 단일행인 경우
      if (startLine === endLine) {
        newText[startLine] =
          editorText[startLine].substring(0, replaceStart) + editorText[startLine].substring(replaceEnd);
      } else {
        newText[startLine] = editorText[startLine].substring(0, replaceStart);
        newText[endLine] = editorText[endLine].substring(replaceEnd);

        for (let i = startLine + 1; i < endLine; i++) {
          editorText[i] = '';
        }
      }

      editorRef.current.getInstance().setMarkdown(newText.join('\n'));
      editorRef.current
        .getInstance()
        .setSelection([startLine + 1, replaceStart + 1], [startLine + 1, replaceStart + 1]);

      if (!(replaceStart == replaceEnd && startIndex == endIndex)) {
        e.preventDefault();
      }
    }
  };

  const handleOnchange = () => {
    const msg = editorRef.current.getInstance().getMarkdown();

    let totalLength = 0;
    btnByte.forEach((item) => {
      // 정규표현식을 이용해 문자열 내의 모든 매치를 찾음
      const regex = new RegExp(item.value, 'g');
      const matches = msg.match(regex);

      if (matches) {
        totalLength += matches.length * item.length;
      }
    });

    setInputByte(getByte(msg) + totalLength);
  };

  const LINE_FEED = 10; // '\n'
  const getByteLength = (decimal) => {
    return decimal >> 7 || LINE_FEED === decimal ? 2 : 1;
  };

  const getByte = (str) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, unicodeDecimalValue) => prev + getByteLength(unicodeDecimalValue), 0);
  };

  const btnByte = [
    { value: '기관명', length: 120 }, // 120 + 8
    { value: '채용공고명', length: 88 }, // 88 + 12
    { value: '채용분야명', length: 88 }, // 88 + 12
    { value: '검사시작시간', length: 2 }, // 2 + 14
    { value: '검사종료시간', length: 2 }, // 2 + 14
    { value: '검사시간', length: -2 }, // -2 + 10
    { value: '접근주소', length: 10 }, // 10 + 10
    { value: '대상자(전화번호)', length: 5 }, // 5 + 18
    { value: '대상자명', length: 10 },
    { value: '전화번호', length: 3 }, // 3 + 10
    { value: 'ID', length: 16 }, // 16 + 4
  ];
  /**
   * Editor 상단 Toolbar 커스텀 버튼 생성
   */
  const createButton = (e) => {
    const el = document.createElement('button');
    el.style.width = 'auto';
    el.style.height = '100%';
    el.style.height = '100%';
    el.style.color = '#d32f2f';
    el.style.background = '#f7f9fc';
    el.style.border = '1px solid #d32f2f';
    el.style.padding = '5px';
    el.innerHTML = e;

    el.addEventListener('click', () => {
      editorRef.current.getInstance().replaceSelection('`' + e + '` ');
    });

    return el;
  };

  /**
   * 안내문구 조회 API
   */
  const fetchDatas = async () => {
    try {
      showLoading();
      const res = await CustomApi.post(`/api/projectadmin/selectGuidanceMsgMng`, {
        msgCd: tabValue,
      });
      if (res.status == 200) {
        if (tabValue === 'E') {
          // editorRef.current.getInstance().setHTML(res.data);
          editorRef.current.getInstance().setMarkdown(res.data);
        } else {
          editorRef.current.getInstance().setMarkdown(res.data);
        }
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  /**
   * 저장 API
   */
  const storeData = async () => {
    if (maxByte < inputByte) {
      showMsg('error', '입력된 정보가 제한길이를 초과하여 저장되지 않았습니다.');
      return;
    }

    try {
      showLoading();
      const res = await CustomApi.post(`/api/projectadmin/insertGuidanceMsgMng`, {
        msgCd: tabValue,
        msgContents:
          tabValue === 'E'
            ? editorRef.current.getInstance().getMarkdown()
            : editorRef.current.getInstance().getMarkdown(),
        // tabValue === 'E' ? editorRef.current.getInstance().getHTML() : editorRef.current.getInstance().getMarkdown(),
      });
      if (res.status == 200) {
        showMsg('info', '입력된 정보가 저장 되었습니다.');
      } else {
        console.error('error');
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  return (
    <Fragment>
      <LayoutAdmin title="안내문구 관리">
        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <CustomButton title="저장" className="inlineButton" onClick={storeData} />
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange}>
              <Tab label="E-Mail" value="E" />
              <Tab label="SMS" value="S" />
            </TabList>
          </Box>
          {/* E-Mail */}
          <TabPanel value="E"></TabPanel>
          {/* SMS */}
          <TabPanel value="S"></TabPanel>
        </TabContext>
        <br />
        <Editor
          toolbarItems={[
            ['heading', 'bold', 'italic', 'strike'],
            ['hr', 'quote'],
            ['ul', 'ol', 'task', 'indent', 'outdent'],
            [
              { el: createButton('기관명') },
              { el: createButton('채용공고명') },
              { el: createButton('채용분야명') },
              { el: createButton('검사시작시간') },
              { el: createButton('검사종료시간') },
              { el: createButton('검사시간') },
              { el: createButton('접근주소') },
              { el: createButton('대상자(전화번호)') },
              { el: createButton('대상자명') },
              { el: createButton('전화번호') },
              { el: createButton('ID') },
            ],
          ]}
          height="550px"
          previewStyle={window.innerWidth > 1000 ? 'vertical' : 'tab'} // 미리보기 스타일 (verttical or tab)
          hideModeSwitch="true" // 하단 마크다운/위지윅 전환 탭 숨김
          onKeydown={handleKeydown}
          onChange={handleOnchange}
          ref={editorRef}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          ( {inputByte} byte / {maxByte} byte )
        </Stack>
      </LayoutAdmin>
    </Fragment>
  );
};

export default GuidanceMsgMng;
